import React from 'react'
import PropTypes from 'prop-types'
import { StyledTextList } from 'styled'

const List = ({ children, element }) => {
  return (
    <StyledTextList>
      {children}
    </StyledTextList>
  )
}

export default List

List.propTypes = {
  children: PropTypes.array,
  element: PropTypes.object
}
