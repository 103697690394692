import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledButton = styled.button`
  width: ${props => (props.width ? props.width : 'initial')};
  height: 45px;

  margin-top: ${props => (props.marginTop ? props.marginTop : 'initial')};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 'initial')};
  padding-top: ${props => (props.paddingTop ? props.paddingTop : 'initial')};
  padding-bottom: ${props => (props.paddingBottom ? props.paddingBottom : 'initial')};
  padding-right: ${props => (props.paddingRight ? props.paddingRight : 'initial')};
  padding-left: ${props => (props.paddingLeft ? props.paddingLeft : 'initial')};
  margin: ${props => (props.margin && props.margin)};
  padding: ${props => (props.padding && props.padding)};
  letter-spacing: 1.5px;
  
  text-transform: uppercase;
  outline: none;
  border: 0;
  line-height: inherit; 
  cursor: ${(props) => props.disabled ? 'default' : 'pointer'};
  color: white;
  font-size: ${props => props.fontSize ? props.fontSize : "14px"};
  font-weight: 600;
  font-family: Raleway;
  background: #E30613;
`; 

const Button = (
  { 
    onMouseDown, 
    onClick, 
    text, 
    fontColor, 
    fontSize, 
    fontWeight, 
    padding, 
    paddingBottom, 
    paddingTop, 
    paddingLeft, 
    paddingRight, 
    margin,
    marginBottom, 
    marginTop, 
    marginLeft, 
    marginRight, 
    style, 
    backgroundColor,
    width, 
    onTouchEnd, 
    onTouchStart, 
    htmlType, 
    type,
    disabled,
    className,
    changeHoverStyle,
    id
  }) => {
  return(
    <StyledButton
      id={id}
      className={className}
      type={type ? type : 'submit'}
      htmlType={htmlType}
      onClick={onClick}
      onMouseDown={onMouseDown}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      fontColor={fontColor}
      fontSize={fontSize}
      fontWeight={fontWeight}
      margin={margin}
      marginTop={marginTop}
      marginBottom={marginBottom}
      marginLeft={marginLeft}
      marginRight={marginRight}
      padding={padding}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      paddingLeft={paddingLeft}
      paddingRight={paddingRight}
      width={width}
      disabled={disabled}
      backgroundColor={backgroundColor}
      changeHoverStyle={changeHoverStyle}
      style={style}
    >
      {text}
    </StyledButton>
  )
};

export default Button;

Button.propTypes = {
  backgroundColor: PropTypes.string,
  changeHoverStyle: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  fontColor: PropTypes.string,
  fontSize: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
  ]),
  fontWeight: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
  ]),
  htmlType: PropTypes.string,
  id: PropTypes.string,
  margin: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
  ]),
  marginBottom: PropTypes.string,
  marginLeft: PropTypes.string,
  marginRight: PropTypes.string,
  marginTop: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
  ]),
  onClick: PropTypes.func,
  onMouseDown: PropTypes.func,
  onTouchEnd: PropTypes.func,
  onTouchStart: PropTypes.func,
  padding: PropTypes.string,
  paddingBottom: PropTypes.string,
  paddingLeft: PropTypes.string,
  paddingRight: PropTypes.string,
  paddingTop: PropTypes.string,
  style: PropTypes.object,
  text: PropTypes.string.isRequired,
  type: PropTypes.string,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
  ])
};