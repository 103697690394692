/**
 * This function creates paths for a Language Select component in the footer for Privatefly diffrent domains. 
 * @author Andrew Bedziak
 * 
 * @param {item} object - element from an array
 * @param {alternateLanguages} array - Array of alternate languages of each page
 * @param {language} string - Language of the current page. 
 * @param {location} object - Location object

 * @returns {string} 
 */

import { createFlagLinksForFrenchLocale } from 'utils/functions/menu/createFlagLinksForFrenchLocale';
import { createFlagLinksForOtherDomains } from 'utils/functions/menu/createFlagLinksForOtherDomains';
import { createFlagLinksForUniqueSwissLocales } from 'utils/functions/menu/createFlagLinksForUniqueSwissLocales';
import { createFlagLinksForSwissEnLocale } from 'utils/functions/menu/createFlagLinksForSwissEnLocale';

import { alternateLanguagesCheck } from 'utils/functions/alternateLanguages/alternateLanguagesCheck';
import { createHostnameForEnv } from 'utils/functions/createHostnameForEnv';

const hostname = createHostnameForEnv();

export const changeDomain = (item, alternateLanguages, language, location) => {
  let domain;
  let pathname;
  let englishPathname;
  let frenchPathname;

  if (location.pathname.startsWith("/fr")) {
    pathname = location.pathname.replace("/fr", "");
  } else { 
    pathname = location.pathname
  }

  const { 
    checkIfSwissFrenchHasEnglishAlternate,
    checkIfSwissEnglishIsUnique,
    checkIfSwissFrenchIsUnique,
    checkIfEnglishHasFrenchAlternatePath,
    checkIfFrenchIsUniqueOnSwissDomain,
    checkIfFrenchHasEnglishAlternate
  } = alternateLanguagesCheck(alternateLanguages, language);

  const countryCode = item.country_code.split('-')[1];
  
  if (process.env.IS_SWISS_DOMAIN === "true") {
    if (checkIfSwissFrenchHasEnglishAlternate) {
      englishPathname = `${checkIfSwissFrenchHasEnglishAlternate.path}`

      if (englishPathname) {
        if ( englishPathname.startsWith("/") ) englishPathname = englishPathname
        else englishPathname = `/${englishPathname}`

        domain = createFlagLinksForFrenchLocale(item, pathname, englishPathname, countryCode, hostname)
      }
      
    } else if (checkIfEnglishHasFrenchAlternatePath) {
      frenchPathname = checkIfEnglishHasFrenchAlternatePath.path

      if (frenchPathname) {
        if ( frenchPathname.startsWith("/") ) frenchPathname = frenchPathname
        else if (frenchPathname === "fr" ) frenchPathname = "/"
        else frenchPathname = `/${frenchPathname}`

        domain = createFlagLinksForSwissEnLocale(item, pathname, frenchPathname, countryCode, hostname)
      }

    } else if (checkIfSwissEnglishIsUnique || checkIfSwissFrenchIsUnique || checkIfFrenchIsUniqueOnSwissDomain) {
      domain = createFlagLinksForUniqueSwissLocales(item, pathname, countryCode, hostname, language)
    }

  } else {
    if (process.env.LANG_CODE === "fr-fr") {
      if (checkIfFrenchHasEnglishAlternate) {
        englishPathname = `${checkIfFrenchHasEnglishAlternate.path}`
        if (location.pathname === "/") englishPathname = location.pathname

        if (englishPathname) {
          if ( englishPathname.startsWith("/") ) englishPathname = englishPathname
          else englishPathname = `/${englishPathname}`
  
          domain = createFlagLinksForFrenchLocale(item, pathname, englishPathname, countryCode, hostname)
        }
      } else {
        domain = createFlagLinksForFrenchLocale(item, pathname, null, countryCode, hostname)
      }
    } else {
      domain = createFlagLinksForOtherDomains(item, pathname, countryCode, hostname, alternateLanguages)
    }
  }

  return domain
}