import React from 'react';
import PropTypes from 'prop-types';

import { StyledFlagLink, StyledLanguageList, LanguageListItem, } from 'components/footer/styled';

import { changeDomain } from 'utils/functions/menu/changeDomain';

const isBlog = process.env.BUILD_BLOG;

const LangList = ({ preview, languagesData, language, location, alternateLanguages, isMobile }) => {
  alternateLanguages = !preview && alternateLanguages && alternateLanguages.filter(el => el !== null);

  const list = languagesData.map((item) => {
    let link = !preview && changeDomain(item, alternateLanguages, language, location);
    let blogDomain = !preview && isBlog && new URL(link);

    let domain = isBlog ? blogDomain.origin : link;
    
    if (isMobile) {
      return (
        <option 
          key={item.flag_image.url} 
          value={item.language_name}
          data-href={domain}
        > 
          {item.language_name} 
        </option>
      )
    } else {
      return (
        <React.Fragment>
          <LanguageListItem key={item.flag_image.url}>
            <StyledFlagLink href={domain}>
              <img src={item.flag_image.url} />
              <p>{item.language_name}</p>
            </StyledFlagLink>
          </LanguageListItem>
        </React.Fragment>
      )
    }
  })

  if (isMobile) return list
  else return <StyledLanguageList>{list}</StyledLanguageList>
}

export default LangList

LangList.propTypes = {
  alternateLanguages: PropTypes.array,
  isMobile: PropTypes.bool,
  language: PropTypes.string,
  languagesData: PropTypes.array,
  location: PropTypes.object,
  preview: PropTypes.bool
}