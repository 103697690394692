import React from 'react'
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import { addHreflangsToAlternateLanguages } from 'SEO/functions/hreflangs/addHreflangsToAlternateLanguages';
import { createHrefLangs } from 'SEO/functions/hreflangs/createHrefLangs';
import { createHrefLangLinksForSwissLanguages } from 'SEO/functions/hreflangs/createHrefLangLinksForSwissLanguages';
import { createHrefLangLinks } from 'SEO/functions/hreflangs/createHrefLangLinks';

import { fireSetCookiesTag } from 'SEO/functions/gtm/fireSetCookiesTag';
import { fireTimerTag } from 'SEO/functions/gtm/fireTimerTag';
import { firePageViewTag } from 'SEO/functions/gtm/firePageViewTag';
import { fireGtmScript } from 'SEO/functions/gtm/fireGtmScript';

import { loadTrustpilotScript } from 'SEO/functions/loadTrustpilotScript';
import { loadTwitterScript } from 'SEO/functions/loadTwitterScript';
import { loadPrismicScriptForPreview } from 'SEO/functions/loadPrismicScriptForPreview';
import { createHostnameForEnv } from 'utils/functions/createHostnameForEnv';

const gtmCode = process.env.GTM_CODE || "GTM-5SMPQCX";
const isSwissDomain = process.env.IS_SWISS_DOMAIN == "true";

const hostname = createHostnameForEnv();

const SEO = ({
  meta, 
  location, 
  alternateLanguages, 
  language, 
  genericAirfield, 
  isHomePage, 
  languageCodes,
  shouldLoadTrustPilotScript,
  preview,
  isBlog,
  phoneNumber
}) => {
 
  let filteredAlternateLanguages;
  let hrefLangUrls;
  let canonicalLink;
  let excludeFlagFromLocalStorage;

  if (typeof localStorage !== 'undefined') {
    excludeFlagFromLocalStorage = localStorage.getItem("excludeFS")
  }

  const pathname = location ? location.pathname : ""

  const keywords = meta && meta.meta_keywords;
  const description = meta && meta.meta_description;
  const title = meta && meta.meta_title; 

  if (!isBlog) {
    canonicalLink = process.env.LOCALE_ORIGIN + location.pathname
  } else {
    canonicalLink = process.env.BLOG_ORIGIN + location.pathname
  }

  const isReviewPage = location.pathname.includes('about/reviews') || location.pathname.includes('a-propos/temoignage');
  let metaDescription = description && description.replace('+44 (0) 1747 642 777', '+44 (0)20 7100 6960');

  if (isSwissDomain) {
    const originalPhoneNumber = language === 'en-gb' ? '+44 (0)20 7100 6960' : '+33 (0)1 70 70 77 87';

    metaDescription = description && description.replace(originalPhoneNumber, phoneNumber);
  }

  const addAlternateHrefLangs = addHreflangsToAlternateLanguages(alternateLanguages, isSwissDomain);

  if (!preview && !isBlog) {
    filteredAlternateLanguages = Array.from(new Map(addAlternateHrefLangs.map(e => [e.hreflang, e])).values());
  }

  if (!isBlog) {
    hrefLangUrls = createHrefLangs(languageCodes, pathname, alternateLanguages, language, genericAirfield, isHomePage);
  }

  if (canonicalLink.endsWith("/")) canonicalLink = canonicalLink.slice(0, canonicalLink.lastIndexOf("/"))

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={metaDescription} />
      <meta name="keywords" content={keywords} />
      { meta.og_title && <meta property="og:title" content={ meta.og_title } /> }
      { meta.og_description && <meta property="og:description" content={ meta.og_description } /> }
      { location && <meta property="og:url" content={location.href} /> }
      { meta.og_image && 
        <meta 
          name="image" 
          property="og:image"
          content={ meta.og_image }
        /> 
      }
      <meta property="og:type" content={isBlog ? "article" : meta.og_type ? meta.og_type : "website"} />
      { meta.og_title && <meta name="author" content="PrivateFly" /> }
      { meta.og_date_published && <meta property="article:published_time" content={meta.og_date_published} /> }
      <script>{`window.dataLayer = window.dataLayer || []; `}</script>

      {loadPrismicScriptForPreview()}
      {loadTrustpilotScript(isReviewPage, shouldLoadTrustPilotScript)}
      {loadTwitterScript(isBlog)}

      {fireGtmScript(gtmCode)}
      {fireSetCookiesTag()}
      {firePageViewTag(pathname, title, excludeFlagFromLocalStorage)}
      {fireTimerTag()}

      <style>{`.async-hide { opacity: 0 !important} `}</style>
      <link rel="canonical" href={canonicalLink} />

      {
        !preview && !isBlog ?
        createHrefLangLinksForSwissLanguages(filteredAlternateLanguages, isSwissDomain, hostname)
        
        : null
      }
      
      {
        !preview && !isBlog ?
        createHrefLangLinks(hrefLangUrls)
        
        : null
      }
    </Helmet>
  )
}

SEO.propTypes = {
  alternateLanguages: PropTypes.array,
  genericAirfield: PropTypes.bool,
  isBlog: PropTypes.bool,
  isHomePage: PropTypes.bool,
  language: PropTypes.string,
  languageCodes: PropTypes.array,
  location: PropTypes.object,
  meta: PropTypes.object,
  phoneNumber: PropTypes.string,
  preview: PropTypes.bool,
  shouldLoadTrustPilotScript: PropTypes.bool
}

const mapStateToProps = state => ({
  shouldLoadTrustPilotScript: state.trustPilotReducer.shouldLoadTrustPilotScript
})

export default connect(mapStateToProps, null)(SEO)