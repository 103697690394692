import React from 'react';
import PropTypes from 'prop-types';

import LangList from 'components/footer/LanguageSelect/LangList.js';

import {
  StyledHeading,
  StyledLangSelectMobile
} from "../styled";

function MobileList({ 
  footerData,  
  countryFlag, 
  defaultFlag, 
  preview, 
  location, 
  language, 
  alternateLanguages
}) {
  const handleSelect = (e) => {
    console.log("e.target", e.target["data-href"])
    console.log("e", e)

    window.location.href = e.target.selectedOptions[0].getAttribute("data-href")
  }

  return (
    <React.Fragment>
      <StyledHeading marginBottom="23px"> 
        {footerData.footer_language_section_heading} 
      </StyledHeading>
      <div style={{display: 'inline-flex', cursor: 'pointer'}}>  
        <StyledLangSelectMobile 
          id="select-lang-mobile" 
          onChange={(e)=>handleSelect(e)}
          value={countryFlag ? countryFlag.language_name : defaultFlag.language_name}
        >
          <LangList 
            preview={preview}
            location={location}
            language={language}
            languagesData={footerData.language}
            alternateLanguages={alternateLanguages}
            isMobile={true}
            countrySelected={countryFlag ? countryFlag.language_name : defaultFlag.language_name}
          />
        </StyledLangSelectMobile>    
        
      </div>
    </React.Fragment>
    
  )
}

MobileList.propTypes = {
  alternateLanguages: PropTypes.array,
  countryFlag: PropTypes.object,
  defaultFlag: PropTypes.object,
  footerData: PropTypes.object, 
  language: PropTypes.string, 
  location: PropTypes.object, 
  preview: PropTypes.bool, 
}

export default MobileList
