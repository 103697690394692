import styledNormalize from 'styled-normalize';
import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
${'' /* normalize.css for styled components */}
  ${styledNormalize}

  body {
    font-family: 'Open Sans', sans-serif;
  }

   h1, h2, h3, h4, h5, h6 {
    font-family: 'Raleway', sans-serif;
  }

  ${'' /* * {
    color: #3C3C3B;
  } */}

  strong, b {
    font-weight: bold !important
  }
`;

