import styled from 'styled-components'

export const StyledBannerImageContainer = styled.div`
  display: ${props => props.isBottomBanner ? "none" : "block"};
  position: relative;
  width: 100%;
  height: ${props => {
    if (props.desktopLarge === true && props.isHomePage) {
      return "178px"
    }
    else if (props.desktopLarge === true) {
      return "484px"
    } else if (props.desktopMedium === true) {
      return "329px"
    } else if (props.desktopSmall === true) {
      return "130px"
    }
  }};

  background-size: cover;
  background-position: ${props => {
    if (props.desktopLarge === true && props.isHomePage) {
      return "bottom"
    }
    else {
      return "center"
    }
  }};
  background-image: ${props => `url(${props.bannerImageSrc}) `};
  filter: ${props => props.isTemplate && "grayscale(100%) sepia(10%)"};


  @media(min-width: 640px) {
    display: block;
    margin-top: ${props => props.isBottomBanner ? "100px" : "0"};
  }
  

  @media(min-width: 1024px) {
    background-image: ${props => `url(${props.bannerImageSrc}) `};
    background-position: center;
    background-position: ${props => {
    if (props.desktopLarge === true && props.isHomePage) {
      return "bottom"
    }
    else {
      return "center"
    }
  }};
  }

  .form-flightSearch{
    display: none !important;

    @media(min-width: 1200px) {
      display: flex !important;
    }
  }

  img {
    width: inherit;
    height: 100%;
    object-fit: cover;
  }

  h1,h2,h3, p {
    color: white;
  }

  @media screen and (max-device-height: 500px) and (max-device-width: 900px) and (orientation:landscape) {
    height: ${props => {
      if (props.desktopLarge === true && props.isHomePage) {
        return "calc(70vh - 62px)"
      } else if (props.desktopLarge === true || props.desktopMedium === true) {
        return "calc(100vh - 62px)"
      } 
    }};

    background-position: ${props => {
      if (props.desktopLarge === true && props.isHomePage) {
        return "bottom"
      }
      else {
        return "center"
      }
    }};
  }

  @media(min-width: 1200px) {
    height: ${props => {
      if (props.desktopLarge === true) {
        return "75vh"
      }
    }};
    
    min-height: ${props => {
      if (props.desktopLarge === true) {
        return "570px"
      }
    }};
  }

  @media(min-width: 1440px) {
    height: ${props => {
      if (props.desktopLarge === true) {
        return "720px"
      } else if (props.desktopMedium === true) {
        return "400px"
      } else if (props.desktopSmall === true) {
        return "173px"
      }
    }};
  }
`

export const StyledBannerInnerContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  height: 100%;
  color: white;
  text-align: ${props => props.isHomePage ? "center" : "left"};
  width: 100%;

  section.banner-inner-section {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: ${props => props.isHomePage ? "flex-start" : "flex-end"};
    justify-content: ${props => props.isHomePage ? "center" : "unset"};
    width: 100%;
    height: 100%;

    .banner-lines-medium-svg {
      display: none;
    }

    @media (min-width: 1024px) {
      .banner-lines-medium-svg {
        position: absolute;
        display: block;
        right: 0;
        right: -100px;
        width: 500px;
        height: 100%;
      }
    }


  }
`

export const StyledTextContainer = styled.div`
  width: 100%;
  margin-top: ${props => {
    if (props.desktopLarge && props.isHomePage) {
      return "86px"
    } else {
      return "0"
    }
  }};


  margin-bottom: ${props => {
    if (props.desktopSmall) {
      return "clamp(20px, 5vw, 24px);"
    } else if (props.desktopMedium) {
        return "45px"
      } else if (props.desktopLarge && !props.isHomePage) {
        return "clamp(44px, 5vw, 177px);"
      }
  }};

  h1 {
    color: #FFFFFF;
    font-family: Raleway;
    font-size: ${props => {
      if (props.isTemplate) {
        return "clamp(24px, 5vw, 36px)"
      } else if (props.isHomePage) {
        return "clamp(24px, 5vw, 48px)"
      } else if (props.desktopSmall) {
        return "clamp(18px, 5vw, 28px)"
      } else if (props.desktopMedium || props.desktopLarge) {
        return "clamp(24px, 5vw, 48px)"
      }
    }};

    font-style: ${props => props.isHomePage && "italic"};

    font-weight: 400;
    letter-spacing: 0;
    
    margin: 0;
    margin-bottom: ${props => {
      if (!props.isHomePage && props.desktopLarge) {
        return "6px"
      } else {
        return "0"
      }
    }};

    @media screen and (min-device-width:320px) and (max-device-width: 1000px) and (orientation:landscape) {
      font-size: 24px;
    }

    @media(min-width: 768px) {
      line-height: 52px !important;
    }
  }

  h2 {
    margin: 0;
    margin-top: 0;
    font-size: ${props => {
      if (props.isHomePage) {
        return "13px"
      } else if (props.isTemplate) {
        return "clamp(18px, 5vw, 26px)"
      } else if (props.desktopMedium || props.desktopLarge) {
        return "clamp(18px, 5vw, 38px)"
      } else {
        return "38px"
      }
    }};
    font-weight: ${props => props.isHomePage ? "600" : "400"};
    font-family: ${props => props.isHomePage ? "Open Sans" : "Raleway"};
    font-style: ${props => !props.isHomePage && "italic" };
    letter-spacing: ${props => props.isHomePage ? "0.93px" : "0"};
    text-transform: ${props => props.isHomePage ? "uppercase" : "normal"};
    line-height: initial !important;

    @media(min-width: 768px) {
      margin-top: 0;
      line-height: 29px !important;
    }

    @media(min-width: 1200px) {
      margin-top: 16px;
      /* margin-top: 0; */
      line-height: 29px !important;
    }

    @media screen and (min-device-width:320px) and (max-device-width: 1000px) and (orientation:landscape) {
      font-size: ${props => {
        if (props.isHomePage) {
          return "13px"
        } else {
          return "18px"
        }
      }};
      line-height: initial !important;
    }


  }

  p {
    position: relative;
    margin-top: ${props => {
      if (props.desktopMedium) {
        return "10px"
      } else if (props.desktopLarge && !props.isHomePage) {
        return "clamp(28px, 5vw, 100px)"
      } else if (props.desktopLarge && props.isHomePage) {
        return "16px"
      } 
    }};

    margin-bottom: 0;
    width: 100%;
    font-family: Raleway;
    font-size: ${props => {
       if (!props.isHomepage) {
        return "clamp(18px, 5vw, 28px)"
      } else {
        return "28px"
      }
    }};
    letter-spacing: 0;
    line-height: 24px;

    @media(min-width: 640px) {
      line-height: 33px;
    }

    @media(min-width: 1200px) {
      width: ${props => props.isBottomBanner && props.desktopLarge ? "50%" : "60%"};
    }

    @media screen and (min-device-width:320px) and (max-device-width: 1000px) and (orientation:landscape) {
      font-size: 18px;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      background: #3C3C3B;
      opacity: 0.25;
    }
  }

  a {
    color: white;

    &:hover, &:visited {
      color: white
    } 
  }

  @media screen and (min-device-width:320px) and (max-device-width: 1000px) and (orientation:landscape) {
    margin-top: ${props => {
      if (props.desktopLarge && props.isHomePage) {
        return "40px"
      } 
    }};
  }

  @media(min-width: 1200px) {
    margin-top: 111px;
    max-width: ${props => props.isTemplate ? "740px" : "unset"};
  }
`