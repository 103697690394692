import React from 'react';
import PropTypes from 'prop-types';

import InternalLink from 'components/links/internalLink';

import { StyledMainMenu } from './styled';

const isBlogCheck = process.env.BUILD_BLOG;
const privateflyDomain = process.env.LOCALE_ORIGIN;
const isSwissDomain = process.env.IS_SWISS_DOMAIN;

const Menu = ({ menuData, previewHeaderData, language, menuOpen, location }) => {

  const isFR = process.env.LANG_CODE === 'fr-fr' || language === 'fr-fr' || language === 'fr-ch';
 
  let activePath = location.pathname;
  
  let selectedMenuIndex;

  const menuItems = menuData.data.body.slice(0, 5).map((item, index) => {
    let menuItemText;
    let url = new URL(item.primary.menu_title_url.url);

    if (previewHeaderData) menuItemText = item.primary.menu_title[0].text
    else menuItemText = item.primary.menu_title.text
    
    // 95754 - menu highlights
    const isDestinationsPage = isFR ? activePath.includes("/vol-prive/") : activePath.includes("/private-jet-hire/");
    const isAircraftPage = isFR ? activePath.includes("/jet-prive/") : activePath.includes("/private-jets/")

    if (isDestinationsPage && menuItemText === "DESTINATIONS") {
        selectedMenuIndex = index;

    } else if (isAircraftPage && (menuItemText === "AIRCRAFT" || menuItemText === "APPAREILS")) {
        selectedMenuIndex = index;

    } else {
      item.items.map((el)=> {
        let subMenuUrl = new URL(el.url_link.url)

        // fix for 105905 - CH/Swiss menu highlight
        if(isSwissDomain == "true") {
          if (language === 'fr-fr' || language === 'fr-ch') {
            activePath = activePath.replace('/fr', '');
          }
          subMenuUrl.pathname = subMenuUrl.pathname.replace(".html", "");
        }

        if(!subMenuUrl.host.includes("blog.privatefly")){
          if (subMenuUrl.pathname === activePath) {
            selectedMenuIndex = index; 
          } 
        }
      })
    }

    return (
      <StyledMainMenu 
        isHighlighted={index === selectedMenuIndex}
        key={item.primary.menu_title_url.url}
        isFR={isFR ? true : false}
      >
        { isBlogCheck ? (
          <a href={`${privateflyDomain}${url.pathname}`}> {menuItemText} </a>
          ) : (
            <InternalLink 
              path={url.pathname} 
              linkText={menuItemText}
              language={language}
            />
          )
        }
      </StyledMainMenu>
    )
  })

  return !menuOpen ? menuItems : null

}

export default Menu

Menu.propTypes = {
   language: PropTypes.string,
   location: PropTypes.object,
   menuData: PropTypes.object,
   menuOpen: PropTypes.bool,
   previewHeaderData: PropTypes.object
}
