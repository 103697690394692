const isSwissDomain = process.env.IS_SWISS_DOMAIN

import { alternateLanguagesCheck } from 'utils/functions/alternateLanguages/alternateLanguagesCheck';
import { createHostnameForEnv } from 'utils/functions/createHostnameForEnv';

import { createFrenchHrefLangLinks } from 'SEO/functions/hreflangs/createFrenchHrefLangLinks';
import { createEnglishHrefLangLinks } from 'SEO/functions/hreflangs/createEnglishHrefLangLinks';
import { createSwissDomainHrefLangLinks } from 'SEO/functions/hreflangs/createSwissDomainHrefLangLinks';

const hostname = createHostnameForEnv();

export const createHrefLangs = (languageCodes, pathname, alternateLanguages, language, genericAirfield, isHomePage) => {

  alternateLanguages = alternateLanguages.filter(el => el !== null && el.path !== null)

  const { checkIfEnglishHasFrenchAlternate } = alternateLanguagesCheck(alternateLanguages, language);
  const { checkIfFrenchHasEnglishAlternate } = alternateLanguagesCheck(alternateLanguages, language);
  const { checkIfSwissEnglishIsUnique } = alternateLanguagesCheck(alternateLanguages, language);
  const { checkIfSwissFrenchIsUnique } = alternateLanguagesCheck(alternateLanguages, language);
  const { checkIfFrenchIsUnique } = alternateLanguagesCheck(alternateLanguages, language);

  let list = languageCodes && languageCodes.map((item, index) => {
    let path
    const countryTLD = item.split('-')[1];
    const countryCode = item.substring(0, item.indexOf('-') + 1) + item.substring(item.indexOf('-') + 1).toUpperCase();
   
    if (isSwissDomain == "false") {
      if (process.env.LANG_CODE === "fr-fr") {
       path = createFrenchHrefLangLinks(
          hostname, 
          pathname, 
          checkIfFrenchIsUnique, 
          checkIfFrenchHasEnglishAlternate, 
          alternateLanguages,
          item,
          countryTLD
        )
      } else {
        path = createEnglishHrefLangLinks(
          hostname, 
          countryTLD, 
          pathname, 
          genericAirfield, 
          alternateLanguages,
          item
        )
      }
    } 

    if (isSwissDomain == "true") {
      path = createSwissDomainHrefLangLinks(
        language, 
        alternateLanguages,
        checkIfSwissEnglishIsUnique, 
        checkIfSwissFrenchIsUnique,
        checkIfEnglishHasFrenchAlternate,
        checkIfFrenchHasEnglishAlternate,
        countryCode,
        countryTLD,
        pathname,
        hostname,
        item
      )
    }

    // Corner case - If path is homepage remove the suffix  
    if (isHomePage) {
      if (path ){
        if ( path.includes('/.html')) {
          path = path.replace('/.html', '/');
        }
        if ( path.includes('.html' ) ) {
          path = path.replace('.html', '/');
        }
      }
    } 
    else {
      if (path && path.includes('/.html')){
        path = path.replace('/.html', '.html');
      }
    }

    return {
      path,
      code: countryCode
    }
  })

  return list
}
