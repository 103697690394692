import React from 'react'
import PropTypes from 'prop-types'

const ExternalLink = ({ 
  url,
  style,
  className,
  linkComponent,
  linkText,
  onMouseEnter,
  onMouseLeave
}) => {
  return (
    <a 
      href={url} 
      style={style}
      className={className}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {linkText ? linkText : linkComponent}
    </a>
  )
}

export default ExternalLink

ExternalLink.propTypes = {
   className: PropTypes.string,
   linkComponent: PropTypes.object,
   linkText: PropTypes.string,
   onMouseEnter: PropTypes.func,
   onMouseLeave: PropTypes.func,
   style: PropTypes.object,
   url: PropTypes.string
}
