import React from 'react'

export function fireSetCookiesTag(){
  return (
    <script>{`
      function fireSetCookiesTag(){
        dataLayer.push({
          'event': 'setCookiesCustomEvent'
        });
      }

      fireSetCookiesTag()
    `}
    </script>
  )
}