// function that replaces phone numbers for each locale
const host = process.env.LOCALE_ORIGIN

export default function replaceLocalePhoneNumber(phoneNumbersArray, lang, uid) {
  let localePhoneNumber;
  let jetCardPhoneNumber;

  const jetCardRegex = /jet-card/

  //check if document exists in current locale
  const filteredUniversalPhoneNumber = phoneNumbersArray.find(el => {
    if (process.env.IS_SWISS_DOMAIN == "true") {
      if (lang === "fr-fr") {
        return el.node.lang === "fr-ch"
      } else if (lang === "en-gb") {
        return el.node.lang === "en-ch"
      } else {
        return el.node.lang === lang
      }
    } else {
      return el.node.lang === process.env.LANG_CODE
    }

  })

  //if the documents does not exist return document from en-gb locale
  const genericUniversalPhoneNumber = phoneNumbersArray.find(el => el.node.lang === "en-gb");

  //jet-card check
  if (jetCardRegex.test(uid)) {
    jetCardPhoneNumber = filteredUniversalPhoneNumber.node.data.jet_card_phone_number

    if (jetCardPhoneNumber) {
      return jetCardPhoneNumber
    } else {
      genericUniversalPhoneNumber.node.data.phone_local
    }
  }
  
if (filteredUniversalPhoneNumber) {
    const localePhoneNumbersArray = filteredUniversalPhoneNumber.node.data.localised_phone

    //check if there phone numbers for minimal locales, this currently exists only in en-gb locale
    if (localePhoneNumbersArray.length <= 0) {
      localePhoneNumber = filteredUniversalPhoneNumber.node.data.phone_local
    } else {
      const findlocalePhoneNumber = localePhoneNumbersArray
        .find(el => {
          //match tld with current locale and return correct phone number from the document
          const tld = host.slice(host.lastIndexOf('.') +1)
  
          if (el.country_code === tld) return el
            else return el.country_code === 'gb'
          })
  
      return findlocalePhoneNumber.phone_number
    }
  } else {
    //return default en-gb phone number for any other locale
    return genericUniversalPhoneNumber.node.data.phone_local
  }

  return localePhoneNumber
}