import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Table from './Table'
import { Unpreformatted } from '../styled';

import { loadTrustPilotScript } from 'state/actions/trustPilot'

const windowGlobal = typeof window !== 'undefined' && window; 

const Preformatted = ({ children, element, loadTrustPilotScript }) => {

  if(element.text.includes('trustpilot.com')) {
    const [ shouldRemoveScrollListener, removeScrollListener ] = useState(false)

    const ref = useRef(null);

    useEffect(() => {
      window.addEventListener("scroll", handleScroll, { passive: true });
    }, [])

    useEffect(() => {
      return () => window.removeEventListener("scroll", handleScroll);
    }, [shouldRemoveScrollListener]);

    const handleScroll = (e) => {

      loadTrustPilotScript(true)

      if (windowGlobal.Trustpilot) {
        removeScrollListener(true)
        windowGlobal.Trustpilot.loadFromElement(ref.current, true);
        const tpWidget = document.getElementById('trustpilot-list');
        
        tpWidget.style.border='1px solid black';
        tpWidget.style.borderRadius='5px';
      }
      
    }

    return(
      <div 
        ref={ref} 
        id="trustpilot-list"
        data-locale="en-GB" 
        data-template-id="539ad60defb9600b94d7df2c"
        data-businessunit-id="4bef996600006400050c0f31" 
        data-style-height="600px"
        data-style-width="100%"
        data-theme="light"
        data-stars="4,5" 
        data-review-languages="en"
      />
    )
  }

  // fix for AB#105866 - preformatted anchor link scroll height
  if (element.text) {
    if (element.text.includes('<a id=')) {
      // extract id from the text
      const getId = element.text.match(/<a id="(.*?)"/);

      if (getId) {
        const id = getId[1];

        return (
          <a id={id} style={{position: 'absolute', marginTop: '-70px'}} />
        )
      }
    }
  }

  if (element.label === 'table') {
    return (
      <Table
        children={children}
        element={element}
      />)
  }
  
  return (
    <Unpreformatted className="preformatted-table" isTable={element.text.includes("<table")}
      dangerouslySetInnerHTML={{ __html: element.text }}
    />
  )
  
}

const mapDispatchToProps = dispatch => ({
  loadTrustPilotScript: shouldLoadTrustPilotScript => dispatch(loadTrustPilotScript(shouldLoadTrustPilotScript))
})

export default connect(null, mapDispatchToProps)(Preformatted);

Preformatted.propTypes = {
  children: PropTypes.array,
  element: PropTypes.object,
  loadTrustPilotScript: PropTypes.func
}