const host = process.env.LOCALE_ORIGIN;
const isSwissDomain = process.env.IS_SWISS_DOMAIN;

export const setFlag = (
  footerData,
  setCountryFlag
) => {
    const tld = host.slice(host.lastIndexOf('.') +1);

    if (footerData.language) {
      footerData.language.find(el => {
        const code = el.country_code.slice(el.country_code.indexOf('-') + 1);
        
        if (code === tld) {
          if (isSwissDomain === "true") {
            if (location.pathname.startsWith("/fr/") || location.pathname === "/fr") {
              if (el.country_code === "fr-ch") {
                setCountryFlag(el)
              } 
            } else {
              if (el.country_code === "en-ch") {
                setCountryFlag(el)
              }
            }
          } else {
            setCountryFlag(el)
          }
        }
      })
  
      if (tld === "com") {
        const defaultFlag = footerData.language.find(el => el.country_code === "en-gb");
  
        setCountryFlag(defaultFlag)
      }
    }

}