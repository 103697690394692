import React from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';

import { StyledRichTextImage, StyledImageCaption } from '../styled';

const isBlog = process.env.BUILD_BLOG;

const Image = ({ element }) => {
  const className = element.label ? element.label : null;
  const src = element.url || '';
  const altText = element.alt || ''

  // Temp fix for Prismic blogs - aligned image: #89793 
  const isBrokenAlignedBlogImage = isBlog && !element.label && element.dimensions.width <= 300 && element.dimensions.height < 250;
  const isSmallBlogImage = isBlog && element.dimensions.width <= 300 || element?.dimensions?.height < 200;

  return (
    <React.Fragment> 
      <LazyLoad
        height={element?.dimensions?.height}
        once
        offset={100}
        placeholder={<img data-src={src} data-alt={altText} />}
        style={ 
          isBrokenAlignedBlogImage ? { float: "right", margin: "10px 0px 10px 10px" } : 
          !isBrokenAlignedBlogImage && !isSmallBlogImage && isBlog ? { margin: "0 20px 30px 20px" } : 
          {}
        }
      >
        { element.linkTo ? (
          <a href={element.linkTo.url}>
            <StyledRichTextImage 
              alt={altText}
              src={src} 
              width={element?.dimensions?.width}
              className={className} 
              ratio={parseInt(220/(element?.dimensions?.width/element?.dimensions?.height)) + 'px'}
              style={!isBrokenAlignedBlogImage && !isSmallBlogImage && isBlog ? 
                { paddingBottom: '0px', boxShadow: 'rgb(212 214 222) -20px 20px 0px 0px', width: '100%'} : {}
              }
            />
            {element.copyright ? <StyledImageCaption>{element.copyright}</StyledImageCaption>: null}
          </a>
        ) : (
          <React.Fragment>
            <StyledRichTextImage
              alt={altText}
              src={src}
              width={element?.dimensions?.width}
              className={className}
              ratio={parseInt(220/(element?.dimensions?.width/element?.dimensions?.height)) + 'px'}
              style={!isBrokenAlignedBlogImage && !isSmallBlogImage && isBlog ? 
                { paddingBottom: '0px', boxShadow: 'rgb(212 214 222) -20px 20px 0px 0px', width: '100%'} : {}
              }
            />
            {element.copyright ? <StyledImageCaption>{element.copyright}</StyledImageCaption>: null}
          </React.Fragment>
          )
        }
      </LazyLoad>
    </React.Fragment>
  )
};

export default Image;

Image.propTypes = {
  element: PropTypes.object
};
