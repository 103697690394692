import React from 'react';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';
import { connect } from 'react-redux';

import { htmlSerializer } from 'utils/richText/HtmlSerializer';
import { linkResolver } from 'utils/linkResolver';

import { replaceTelephoneNumbers } from 'components/richText/replaceTelephoneNumbers';

const isBlog = process.env.BUILD_BLOG == 'true';

const RenderRichText = ({ richTextArray, phoneNumber, blogAritclePhoneNumber, }) => {
  const checkIfTextIsEmpty = richTextArray && richTextArray.every(el => el.text === "");

  if (checkIfTextIsEmpty) return null 
  else {
    replaceTelephoneNumbers (richTextArray, isBlog ? blogAritclePhoneNumber : phoneNumber); 
  
    richTextArray && richTextArray.map(el => {
  
      if (!el.spans) {
        let spans = {spans: [{
          end: 0,
          start: 0,
          type: 'strong'
        }]};
        
       Object.assign(el, spans);
      }
    })
  
    return (
      <React.Fragment>
        {RichText.render(richTextArray, linkResolver, htmlSerializer)}
      </React.Fragment>
    )
  }

}

const mapStateToProps = state => ({
  phoneNumber: state.phoneNumberReducer.phoneNumber
})

export default connect(mapStateToProps, null)(RenderRichText);

RenderRichText.propTypes = {
  blogAritclePhoneNumber: PropTypes.string,
  isBlog: PropTypes.bool,
  phoneNumber: PropTypes.string,
  richTextArray: PropTypes.array,
}