import React from 'react'
import PropTypes from 'prop-types'
import { H3 } from 'styled'

const Heading3 = ({ children, richText, content, element }) => {
  return (
    <React.Fragment>
      <H3
        fontSize="24px"
        fontWeight="400"
        richText={richText}
        name={element.label && element.label}
      >
        {children}
      </H3>
    </React.Fragment>
  )
}

export default Heading3

Heading3.propTypes = {
  children: PropTypes.array,
  content: PropTypes.string,
  element: PropTypes.object,
  richText: PropTypes.bool,
}
