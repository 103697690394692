import React from 'react'

export function fireTimerTag() {
  return (
    <script>{`
      function fireTimerTag() {
        let data = sessionStorage.getItem('customTriggerFired');

          if (data === null) { 
            dataLayer.push({
              'event':'customTriggerEvent'
            });
          }

        sessionStorage.setItem('customTriggerFired', 'true');
      } 
      
      setTimeout(function() {
        fireTimerTag()
      }, 2000)

    `}
    </script> 
  )
}