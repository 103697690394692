import styled, {css} from 'styled-components';

export const StyledHeaderNav = styled.nav`
  display: none;

  @media(min-width: 1024px) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  }
`

export const StyledPrimaryMenuWrapper = styled.nav`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
`

export const StyledPhoneWrapper = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 7px 7px 7px 7px;
  border-left: 1px solid rgba(60, 60, 59, 0.2); 
  border-right: 1px solid rgba(60, 60, 59, 0.2); 
  text-align: center;

  @media(min-width: 1200px) { 
    padding: 7px 14px 7px 19px; 
  }

  .header-phone-text {
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0.93px;
    margin-bottom: 5px;
  }

  .fadein-text{
    animation: fadein 1s;
      -moz-animation: fadein 1s; /* Firefox */
      -webkit-animation: fadein 1s; /* Safari and Chrome */
      -o-animation: fadein 1s;
  
    @keyframes fadein { from { opacity:0;} to { opacity:1; } }
    @-moz-keyframes fadein { /* Firefox */ from { opacity:0; } to { opacity:1; } }
    @-webkit-keyframes fadein { /* Safari and Chrome */ from { opacity:0; } to { opacity:1; } }
    @-o-keyframes fadein { /* Opera */ from { opacity:0; } to { opacity: 1;} }    
  }

  .header-phone-number {
    font-family: Raleway;
    font-size: 15px;
    letter-spacing: -0.23px;
    font-weight: 500;
    margin-bottom: 0px;
    color: #000000;

    &:hover{
      text-decoration: underline;
    }

    @media(min-width: 1200px) { 
      font-size: 18px; 
    }
  }

  .fadein-phone{
    animation: fadein 2s;
      -moz-animation: fadein 2s; /* Firefox */
      -webkit-animation: fadein 2s; /* Safari and Chrome */
      -o-animation: fadein 2s;

    @keyframes fadein { from { opacity:0;} to { opacity:1; } }
    @-moz-keyframes fadein { /* Firefox */ from { opacity:0; } to { opacity:1; } }
    @-webkit-keyframes fadein { /* Safari and Chrome */ from { opacity:0; } to { opacity:1; } }
    @-o-keyframes fadein { /* Opera */ from { opacity:0; } to { opacity: 1;} }
  }
`

export const StyledLogoWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-width: 134px;
  max-height: 29px;
  @media(min-width: 1200px) {
    max-width: 154px;
  }
`

export const StyledMainMenu = styled.div`
  display: inline-block;
  box-sizing: border-box;
  letter-spacing: 0px;
  margin-right: 0.9rem;

  ${props => 
    props.isFR &&
      css`
        text-align: center;
      `
  }
  
  @media(min-width: 1200px) {
    margin-right: 20px;
    letter-spacing: 0.93px;
  }

  a {
    color: #3C3C3B;
    font-size: 1.02vw;
    font-weight: 600;
    padding: ${props => props.isHighlighted ? "27px 0": "none"};
    border-bottom: ${props => props.isHighlighted && !props.isFR ? "2px solid #3C3C3B": "none"};

    ${props => 
      props.isFR && props.isHighlighted &&
        css`
          text-align: center;
          text-decoration: underline;
          text-decoration-thickness: 2px;
        `
    }

    
    &:hover {
      padding: ${props => !props.isHighlighted && "27px 0"};
      border-bottom: ${props => !props.isHighlighted && !props.isFR ? "2px solid #3C3C3B" : "none"};
      ${props => 
        props.isFR &&
          css`
            text-decoration: underline;
            text-decoration-thickness: 2px;
          `
      }
    }

    @media(min-width: 1200px) {
      font-size: 12px;
    }
    
    @media(min-width: 1300px) {
      font-size: 13px;
    }

  }
`;

export const StyledLoginWrapper = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0px 27px 0px 22px;

  .login-link {
    color: #3C3C3B;
    font-size: 10px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.93px;
    line-height: 11px;
    margin: 0px;

    &:hover {
      text-decoration: underline;
    }

  }
`;

export const StyledLogoutWrapper = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin: 0px 27px 0px 22px;
  font-size: 10px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.93px;
  
  a { 
    color: #3C3C3B !important;
    :hover{
      text-decoration: underline;
    }
  }
`;

export const StyledHeaderIconWrapper = styled.div`
  display: -webkit-box; 
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;  
  height: 24px;
  width: 24px;
  margin-bottom: 6px;

  img { 
    max-width: 24px; 
  }
`;

export const StyledHamburgerMenu = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;

  .hamburger-icon-text {
    color: #3C3C3B;
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.93px;
    line-height: 11px;
    cursor: pointer;
    margin: 0px;
  }
`;

export const StyledOverlay = styled.div`
  position: fixed;
  top: 100px;
  bottom: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  cursor: auto;
  z-index: 98;
`

export const StyledMenuItemsWrapper = styled.div`
  position: absolute;
  visibility: ${props => props.isActive ? "visible": "hidden"};
  opacity: ${props => props.isActive ? "1": "0"};
  z-index: ${props => props.isActive ? "99": "0"};
  background: #D4D6DE;
  margin: 0;
  line-height: 35px;
  top: 80px;
  left: 0px;
  width: 100%;
  -webkit-transition:opacity 0.3s linear;
  -o-transition:opacity 0.3s linear;
  transition:opacity 0.3s linear;
`

export const StyledSubMenu = styled.div`
  padding: 46px 0 0 0;
`;

export const StyledExternalLink = styled.a`
  color: #3C3C3B;
  font-size: 14px;
  
  :hover {
    color: #3C3C3B;
  }
`

export const StyledSubMenuLinksWrapper = styled.div`
  letter-spacing: 0;
  line-height: 14px;
  margin-bottom: 14px;

  a {
    font-size ${props => props.isFrench ? "11px": "13px"};

    @media(min-width: 1200px) {
      font-size: 13px;
    }
  }

  a:hover {
    text-decoration: underline;
  }
  .active-path{
    font-weight: bold;
    text-decoration: underline;
  }
`;

export const StyledSubMenuHeading = styled.div`
  font-size: ${props => props.isFrench ? "11px": "13px"};
  font-weight: 600;
  letter-spacing: 0.93px;
  line-height: 14px;
  border-bottom: 2px solid black;
  padding-bottom: 15px;

  @media(min-width: 1200px) {
    font-size: 13px;
  }
  
  a {
    color: #3C3C3B;
    text-decoration: none;
  }
`;

export const StyledErrorMessage = styled.div`
  margin-top: 20px;
  margin-bottom: -45px;

  .warning-icon { 
    vertical-align: middle; 
  }

  .search-error-msg {
    font-family: Raleway, sans-serif;
    font-size: 12px;
    color: #46344e;
  }
`;

export const StyledSearchForm = styled.form`
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  width: 100%;
`

export const StyledSearchInput = styled.input`
  width: 100%;
  height: 45px;
  padding: 6px 12px;
  margin-bottom: 93px;
  border: ${props => (props.setErrorBorderColor ? '1px solid red' : '1px solid #B4B5B4')};
  margin-top: 50px;

  ::-webkit-input-placeholder {
    color: #555;
    font-size: 1em;
    font-weight: 300;
  }

  ::-moz-placeholder {
    color: #555;
    font-size: 1em;
    font-weight: 300;
  }

  :-ms-input-placeholder {
    color: #555;
    font-size: 1em;
    font-weight: 300;
  }

  ::-ms-input-placeholder {
    color: #555;
    font-size: 1em;
    font-weight: 300;
  }

  ::placeholder {
    color: #555;
    font-size: 1em;
    font-weight: 300;
  }
`

export const StyledSearchIcon = styled.input`
  position: absolute;
  width: 24px;
  right: 15px;
  bottom: 104px;
`;