import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import InternalLink from 'components/links/internalLink';

import { closeMenu } from 'state/actions/drawer';

import { StyledLink, StyledLi, StyledFlagLink, StyledExternalLink } from '../styled';

const isBlogBuild = process.env.BUILD_BLOG;
const blogOrigin = process.env.BLOG_ORIGIN;
const privateflyDomain = process.env.LOCALE_ORIGIN;
const isSwissDomain = process.env.IS_SWISS_DOMAIN;

const LinkList = ({ sublist, language, location, closeMenu }) => {
  
  let activePath = location.pathname;
  const isLanguageFR = language === 'fr-fr' || language === 'fr-ch';
  const isSwissFR = isSwissDomain == "true" && isLanguageFR;

  const list = sublist.map(el => {
    let url
    let dropDownMenu;

    switch (el.url_link.link_type) {
      case 'Web':
        //convert absolute url too relative path
        url = new URL(el.url_link.url);

        // fix for 105905 - CH/Swiss menu highlight
        if (isSwissDomain == "true") {
          if(isLanguageFR) { 
            activePath = activePath.replace('/fr', '');
          }
          url.pathname = url.pathname.replace('.html', '');
        }

        dropDownMenu = url.pathname ==='/' ? (
          <StyledFlagLink href={url.href} key={url.href}> 
            {el.list_item} 
          </StyledFlagLink>
        ) : (
          <InternalLink 
            linkText={el.list_item} 
            path={url.pathname}
            key={el.url_link.url}
            className="header-dropdown-link"
            language={language}
          />
        )
        
        if (isBlogBuild) {
          dropDownMenu = (
            <StyledExternalLink 
              href={`${privateflyDomain}${url.pathname}`}
              key={url.href}
            >
              {el.list_item}
            </StyledExternalLink>
          )

        } else {

          if (url.pathname === "/") {
            const isBlogSubmenuUrl = el.url_link.url.includes('blog.privatefly');
            const blogHref = isBlogSubmenuUrl && isSwissFR ? 'https://blog.privatefly.fr/' : blogOrigin || url.href;
  
            dropDownMenu = (
              <StyledExternalLink href={blogHref} key={url.href}>
                {el.list_item}
              </StyledExternalLink>
            )

          } else {

            const pathName = isSwissFR ? `/fr${url.pathname}` : url.pathname;

            dropDownMenu = ( 
              <StyledLink 
                to={pathName} 
                key={el.url_link.url}
                className={url.pathname === activePath ? 'active-path' : ''}
                onClick={(e) => e.target.href === window.location.href && closeMenu(false)}
              > 
                {el.list_item} 
              </StyledLink>
            )
          }

        }

        break
      case 'Media':
        dropDownMenu = <StyledExternalLink href={el.url_link.url} key={el.url_link.url}> {el.list_item} </StyledExternalLink>
        break
      case 'Document':
        dropDownMenu = ``
        break
      default:
        dropDownMenu = ``
    }

    return dropDownMenu === '' ? null : 
    <StyledLi 
      length={sublist.length} 
      key={el.url_link.url}
    > 
      {dropDownMenu} 
    </StyledLi>
  })

  return <React.Fragment>{list}</React.Fragment>
}

LinkList.propTypes = {
  closeMenu: PropTypes.func,
  language: PropTypes.string,
  location: PropTypes.object,
  sublist: PropTypes.array
}

const mapDispatchToProps = dispatch => ({
  closeMenu: isMenuClosed => dispatch(closeMenu(isMenuClosed))
})

export default connect(null, mapDispatchToProps)(LinkList)
