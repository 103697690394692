import React from 'react'

export function firePageViewTag(pathname, title, excludeFlagFromLocalStorage) {
  return (
    <script>{ `
      function firePageViewTag(){
        dataLayer.push({
          'event': 'pageviewCustomEvent',
          'page': "${pathname}",
          'title': "${title}",
          'excludeFS': "${excludeFlagFromLocalStorage}",
        });
      }

      firePageViewTag()
    `}
    </script> 
  )
}