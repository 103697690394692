import React from 'react';
import PropTypes from 'prop-types';
import InternalLink from 'components/links/internalLink';

import { StyledLogoWrapper } from '../styled';

const isBlog = process.env.BUILD_BLOG;
const host = process.env.LOCALE_ORIGIN;

const Logo = ({ imgSrc, altText, language }) => {
  return (
    <StyledLogoWrapper > 
      { isBlog ? (
        <a href={`${host}`} style={{textDecoration: 'none'}}>
          <img 
            src={imgSrc} 
            alt={altText} 
            className="mobile-logo"
          />
        </a>
      ) : (
        <InternalLink 
          linkComponent={
            <img 
              src={imgSrc} 
              alt={altText} 
              className="mobile-logo"
            />
        } 
          language={language}
          path="/"
        />
      )
    }
    </StyledLogoWrapper>
  )
};

export default Logo;

Logo.propTypes = {
  altText: PropTypes.string,
  imgSrc: PropTypes.string,
  language: PropTypes.string,
};

