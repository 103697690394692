import React from 'react'
import PropTypes from 'prop-types'

import { TableStyle } from 'styled'

const Table = ({ children, element }) => {
  return (
    <TableStyle
      dangerouslySetInnerHTML={{ __html: element.text }}
    />
  )
}

export default Table

Table.propTypes = {
  children: PropTypes.array,
  element: PropTypes.object
}