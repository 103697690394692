import React from 'react'
import PropTypes from 'prop-types'

import { Para } from 'styled'

const Paragraph = ({ children, id, element, style }) => {
  let classCentered;

  if (element.spans.length > 0) {
    element.spans.map(el => {
      if (el.data) {
        if (el.data.label === "CTAButton" || el.data.label === "center-text") {
          classCentered = 'centered-paragraph'
        }
      }
    })
  }

  return (
    <Para 
      fontSize="14px"
      fontColor='rgb(70, 52, 78)'
      key={id}
      className={element.label ? element.label : classCentered}
      style={style}
    >
      {children}
    </Para>
  )
}

export default Paragraph

Paragraph.propTypes = {
  children: PropTypes.array,
  element: PropTypes.object,
  id: PropTypes.number,
  style: PropTypes.object
}
