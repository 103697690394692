import React from 'react'
import PropTypes from 'prop-types'

import { StyledBannerImageContainer, StyledBannerInnerContainer, StyledTextContainer } from "components/banner/styled";
import { FullWidthContainer } from 'components/grid/styled';
function Banner({ 
  desktopLarge, 
  desktopMedium, 
  desktopSmall, 
  isHomePage, 
  bannerImageSrc,
  headingOne,
  headingTwo,
  headingThree,
  flightSearchComponent,
  text,
  buttonComponent,
  isBottomBanner,
  isTemplate
}) {

  return (
    <StyledBannerImageContainer 
      desktopLarge={desktopLarge} 
      desktopMedium={desktopMedium}
      desktopSmall={desktopSmall}
      isHomePage={isHomePage}
      bannerImageSrc={bannerImageSrc}
      isBottomBanner={isBottomBanner}
      isTemplate={isTemplate}
      id={isBottomBanner ? "bottom-banner-image-container" : "top-banner-image-container"}
    >
      <StyledBannerInnerContainer
        desktopMedium={desktopMedium}
        desktopLarge={desktopLarge} 
        desktopSmall={desktopSmall}
        isHomePage={isHomePage}
      >
        <FullWidthContainer 
          isBannerImage={true}
        >
          <section className="banner-inner-section">
            <StyledTextContainer 
              desktopMedium={desktopMedium}
              desktopLarge={desktopLarge} 
              desktopSmall={desktopSmall}
              isBottomBanner={isBottomBanner}
              isHomePage={isHomePage}
              isTemplate={isTemplate}
            >
              {headingOne}
              {headingTwo}
              {headingThree}
              {text}
              {buttonComponent}
            </StyledTextContainer>
            {flightSearchComponent}
          </section>
        </FullWidthContainer>
      </StyledBannerInnerContainer> 
    </StyledBannerImageContainer>
  )
}

Banner.propTypes = {
  bannerImageSrc: PropTypes.string,
  buttonComponent: PropTypes.object,
  desktopLarge: PropTypes.bool,
  desktopMedium: PropTypes.bool,
  desktopSmall: PropTypes.bool,
  flightSearchComponent: PropTypes.object,
  headingOne: PropTypes.object,
  headingThree: PropTypes.object,
  headingTwo: PropTypes.object,
  isBottomBanner: PropTypes.bool,
  isHomePage: PropTypes.bool,
  isTemplate: PropTypes.bool,
  text: PropTypes.object,
}

export default Banner

