import React from "react";
import PropTypes from "prop-types";
import { H2 } from "styled";

const Heading2 = ({ children, richText}) => (
  <H2
    fontSize="26px"
    fontWeight="400"
    richText={richText}
  >
    {children}
  </H2>
);

export default Heading2;

Heading2.propTypes = {
  children: PropTypes.array,
  richText: PropTypes.bool
};
