import styled from 'styled-components';

export const H1 = styled.h1`
  font-size: ${props => (props.fontSize ? props.fontSize : "14px")};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : "normal")};
  line-height: 35px !important;
  margin-top: ${props => {
    if (props.richText) {
      return '20px'
    } else if (props.marginTop) {
      return props.marginTop
    } else {
      return '0.5em'
    }
  }};
  margin-bottom: ${props => {
    if (props.richText) {
      return "20px"
    } else if (props.marginBottom) {
      return props.marginBottom
    } else {
      return "20px"
    }
  }};
  color: #3C3C3B;
`;

export const H2 = styled.h2`
  margin-top: ${props => {
    if (props.richText) {
      return '20px'
    } else if (props.marginTop) {
      return props.marginTop
    } else {
      return '0.5em'
    }
  }};
  margin-bottom: ${props => {
    if (props.richText) {
      return "20px"
    } else if (props.marginBottom) {
      return props.marginBottom
    } else {
      return '0.5em'
    }
  }};
  padding-top: ${props => (props.paddingTop ? props.paddingTop : 'initial')};
  line-height: ${props => (props.lineHeight ? props.lineHeight : '29px !important')};
  font-size: ${props => {
    if (props.fontSize) {
      return props.fontSize
    } else return "14px"
   }};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : "400")};

  @media (min-width: 1000px) {
    font-size: ${props => {
      if (props.fontSizeDesktop) {
        return props.fontSizeDesktop
      } 
    }};
  }
  color: #3C3C3B;
`;

export const H3 = styled.h3`
  margin-left: 0;
  margin-right: 0;
  margin-top: ${props => {
    if (props.richText) {
      return '20px'
    } else if (props.marginTop) {
      return props.marginTop
    } else {
      return '0.5em'
    }
  }};
  margin-bottom: ${props => {
    if (props.richText) {
      return "20px"
    } else if (props.marginBottom) {
      return props.marginBottom
    } else {
      return '0.5em'
    }
  }};
  padding-bottom: ${props => (props.paddingBottom ? props.paddingBottom : 'initial')};
  padding-top: ${props => (props.paddingTop ? props.paddingTop : 'initial')};
  line-height: 24px !important;
  font-size: ${props => (props.fontSize ? props.fontSize : "14px")};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : "400")};
  color: ${props => (props.color ? props.color : "#3C3C3B")};
  /* color: ${props => {
    if (props.richText) {
      return 'rgb(70, 52, 78)'
    } else if (props.fontColor) {
      return props.fontColor
    } else return 'rgb(70, 52, 78)'
  }}; */

  @media(min-width: 1000px) {
    font-size: ${props => (props.desktopFontSize && props.desktopFontSize)};
  }

  &:nth-of-type(1) {
    margin-top: ${props => (props.richText && '19px')};
  }
`;

export const H4 = styled.h4`
  font-size: ${props => (props.fontSize ? props.fontSize : "14px")};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : "normal")};
  /* color: ${props => {
    if (props.richText) {
      return 'rgb(70, 52, 78)'
    } else if (props.fontColor) {
      return props.fontColor
    } else return 'rgb(70, 52, 78)'
  }}; */
  color: #3C3C3B;
  line-height: 20px !important;
`;

export const H5 = styled.h5`
  font-size: ${props => (props.fontSize ? props.fontSize : "14px")};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : "normal")};
  color: #3C3C3B;
  /* color: ${props => {
    if (props.richText) {
      return 'rgb(70, 52, 78)'
    } else if (props.fontColor) {
      return props.fontColor
    } else return 'rgb(70, 52, 78)'
  }}; */
  line-height: 20px !important;
`;

export const H6 = styled.h6`
  font-size: ${props => (props.fontSize ? props.fontSize : "14px")};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : "normal")};
  line-height: 20px !important;
  color: #3C3C3B;
`;

export const Para = styled.p`
  font-size: ${props => (props.fontSize ? props.fontSize : "14px")};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : "normal")};
  width: 100%;
  margin-bottom: 20px;
  font-family: Open Sans, sans-serif;
  color: #636463;
  font-family: "Open Sans";
  letter-spacing: 0;
  line-height: 16px;

  &::first-letter{
    text-transform: capitalize
  }
`;

export const TableStyle = styled.table`
  margin: 1em 0 1em 0;
  width:100%;
  display: flex;
  overflow: auto;

  img {
    margin:unset;
  }

  th, tr, td {
    padding: 0.2em;
    max-width:100%;
    word-wrap: break-word;
  }
`

export const StyledTextList = styled.ul`

  li {
    line-height: 25px;
    font-size: 14px;
  }

  .dashed {
    list-style: none;
    padding-left: 0;
    font-weight: 300;

    > li {
      margin-left: 15px;
    }
    
    /* Prevent nested li's from getting messed up */

    > li::before {
      content: "- ";
      margin-left: -15px;
    }
  }
`;

export const IndexImg = styled.img`
  width: 150px;
  height: 150px;
`;

export const MapContainer = styled.section`
  width: 100%;
  height: ${props => (props.height)};
`;

export const TextWrap = styled.div`
  margin-top: 1em;
  margin-bottom: 1em;

  @media (min-width: 1000px) {
    margin-top: 2.5em;
    margin-left: 1.3em;
  }
`;

export const IndexInnerWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: 1em;
  margin-bottom: 2em;

  @media (min-width: 1000px) {
    width: 90%;
    display: flex-inline;
    flex-wrap: nowrap;
  }
`;

export const Summary = styled.p`
  margin: 0.5em 0;
`;

export const SpecsCol = styled.div`
  width: 100%;
  border-bottom: solid thin #c4c0c6;
  padding: 0.5rem 0 0.5rem 0;
  :last-child {
    border-bottom: none;
  }
  @media (min-width: 950px) {
    width: 33%;
  }
`;

export const SpecName = styled.div`
  display: inline-block;
  font-weight: 900;
  min-width: 140px;
  @media (min-width: 950px) {
    min-width: 110px;
  }
`;

export const SpecValue = styled.div`
  display: inline;
  @media (min-width: 950px) {
  }
`;

export const DestinationSection = styled.div`
  display: inline-flex;
  width: 100%;
  justify-content: space-evenly;
  padding: 0.5rem;
  text-align: center;
  flex-wrap: wrap;
  @media (min-width: 950px) {
    width: 100%;
  }
`;

export const DestinationDiv = styled.div`
  width: 100%;
  padding-bottom: 1.5rem;
  text-align: center;
  margin: 0.5rem;

  @media (min-width: 950px) {
    width: 31%;
    text-align: left;
  }
`;

export const DestinationSummary = styled.div`
  padding-top: 0.5rem;
  text-align: left;
`;

export const StyledPressReleasesWrapper = styled.div`
  margin-bottom: 30px; 
  width: 100%;

  @media(min-width:1024px) {
    max-width: 95%;
  }

  button {
    letter-spacing: 0.93px;
    padding-left: 0px;
    margin: 40px 0px;
  }

  .press-release-articles {
    color: #636463;
    margin-bottom: 30px; 
    text-transform: capitalize;
    a { color: #4990E2; }
  }
`;

export const StyledGreyList = styled.ul`
  list-style: none;
  padding-left: 0;
  background: #efefef;

  li {
    display: flex;
    text-transform: uppercase;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-left: 10px;
    border-top: 1px solid rgba(70, 52, 78, 0.15);

    &:last-child {
      border-bottom: 1px solid rgba(70, 52, 78, 0.15);
    }
  }

  div, a {
    display: flex;
    align-items: center;
    font-size: 12px;

    @media (min-width: 768px) {
      font-size: 14px
    }
  }

  div {
    flex-grow: 1;
    white-space: nowrap;
    width: 40%;
  }

  .publication-date-cell {
    text-transform: initial;
  }

  a {
    width: 60%;
    flex-grow: 2;
    padding-right: 1em;

    &:hover {
      text-decoration: underline;
    }
  }

  @media(min-width: 425px) {
    div {
      width: 30%;
    }

    a {
      width: 70%
    }
  }

  @media(min-width: 645px) {
    li {
      height: 55px;
    }

    div {
      width: 20%;
    }

    a {
      width: 80%
    }
  }
`

// slice - table of contents

export const TableOfContentsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  text-transform: capitalize;
  margin: ${props => (props.emptyLegs ? '0' : '0 0 20px 0')};

  a {
    position: relative;
    color: #4990e2;
    font-weight: 300;
    padding: 0 8px;
    
    &:hover {
      text-decoration: underline;
    }

    &:first-child {
      padding-left: 0;
    }

    &:after {
      content: '';
      position: absolute;
      border-left: 1px solid grey;
      top: 15%;
      right: 0px;
      height: 70%;
    }

    &:last-child {
      &:after {
        content: '';
        position: absolute;
        border-left: none;
        top: 15%;
        right: 0px;
        height: 70%;
      }
    }
  }
`

// press release page - Commenting out as Marketing team requested this a while ago but need to ask again before deleting
// export const StyledPressReleaseSection = styled.section`
//   display: flex;
//   flex-direction: column;
//   align-items: center;

//   @media (min-width: 576px) {
//     justify-content: space-around;
//     flex-direction: row;
//   }
// `

export const StyledDestinationsSummary = styled.div`
  h1, p {
    color: white;
  }
`

export const DestinationsCardWrapper = styled.div`
  width: 100%;

  @media (min-width: 1000px) {
    display: flex;
    justify-content: space-between;
    flex-wrap: ${props => (props.countries ? 'no-wrap' : 'wrap')};
  }
`

export const StyledEmptyLegsTableOfContentsWrapper = styled.p`

  a {
    position: relative;
    padding: 0 0.5em;
    color: #4990e2;

    &:after {
      content: '';
      position: absolute;
      border-right: 1px solid grey;
      top: 15%;
      right: 0px;
      height: 13px;
    }

    &:hover {
      text-decoration: underline;
    }

    &:last-child {
      &:after {
        content: '';
        position: absolute;
        border-right: none;
        top: 15%;
        right: 0px;
        height: 13px;
      }
    }
  }
`

export const StyledSiteMapList = styled.ul`
  list-style: none;

  * {
    list-style: none;
  }

  li {
    font-family: Open Sans, "sans-serif";
  }


  a {
    color: rgb(73, 144, 226) !important;
  }

  a:hover {
    color: #23527c;
    text-decoration: underline;
  }

  .toplevel-sitemap-list-item {
    min-height: 45px;
    line-height: 30px;
  }

  .level1-sitemap-list-item, .level2-sitemap-list-item {
    position: relative;
    color: rgb(73, 144, 226) !important;

    a {
      color: rgb(73, 144, 226) !important;
    }
    

    &::before {
      content: '·';
      font-size: 30px;
      vertical-align: top;
      color: #e30613;
      display: inline-block;
      margin-top: -2px;
      margin-right: 7.5px;
    }
  }
`

export const StyledNestedSiteMapList = styled.ul`
  overflow: ${props => (props.height === props.topIndex && props.close != true ? "visible" : 'hidden')};
  max-height: ${props => (props.height === props.topIndex && props.close != true ? "10000px" : 0)};
  
  transition: max-height 0.75s;
`

export const StyledSiteMapButton = styled.span`
  margin-right: 25px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    display: inline-block;
    width: 30px;
    height: 30px;
    left: -20px;
    background: ${props => `url(${props.closeIcon}) `};
    background-repeat: no-repeat;
    background-size: 50% 50%;
    background-position: 50% 50%;
    text-align: center;
    transform: rotate(45deg);
    border-radius: 50%;
    border: solid 2px #c7c1c9;
    outline: none;
    color: red;

    &:hover {
      border: solid 2px #E30613; 
    }
  }
  
  :hover::before {
    cursor: pointer;
    border: solid 2px #E30613;
    transition: all 0.5s
  }
`

export const StyledSearchResultsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap; 
  justify-content: space-between;
  flex-direction: column;
  width: 100%;

  a {
    &:hover {
      text-decoration: none !important;
    }
  }

  & > div {
    width: 100%;
    text-overflow: ellipsis;
    margin: 10px 0px 15px 0px;
    border-top: 1px solid #D4D6DE;
    padding-top: 15px;
    
    @media (min-width: 1000px) {
      width: 30%;
      margin: 0px 0px 40px 0px;
      border-top: none;
      padding-top: none;
    }
  }
  
  @media (min-width: 1000px) {
    flex-direction: row;
  }
`;

export const StyledCard = styled.div`
  &&&&&& {
    width: 100%;
    width: ${props => (props.destinations ? 'initial' : 'initial')};
    display: ${props => (props.display ? 'flex' : 'none')};
    flex-direction: column;
    align-items: ${props => (props.destinations && 'center')};
    /* overflow: hidden; */
    margin-left: ${props => (props.destinations ? 'initial' : 'initial')};
    margin-right: ${props => (props.destinations ? 'initial' : 'initial')};
    margin-bottom: ${props => (props.marginBottom)};
    color: ${props => (props.fontColor)};

    .destination-card-carousel {
      width: 100vw;
    }

    h3 {
      font-size: ${props => (props.destinations ? '22px' : 'initial')};
    }

    h3, p {
      color: ${props => (props.fontColor)};
    }

    @media (min-width: 600px) {
      flex-direction: ${props => (props.destinations ? 'column' : 'row')};
    }

    @media (min-width: ${props => (props.destinations ? '1000px' : '1200px')}) {
      width: 27.5%;
      margin-right: calc((100% - (27.5% * 3)) / 2);
      flex-direction: column;
      margin-top: 20px;

      &:nth-child(3n + 0) {
        margin-right: 0 !important
      }

      &:last-child {
        margin-right: ${props => (props.destinations && 0)}
      }
    }

    .card-image-wrapper {
      overflow: hidden;
      min-width: 303px;
      max-width: 305px;
      height: 163px;
      
      @media (min-width: 1200px) {
        width: 100%;
      }
    }

    img {
      min-width: 303px;
      max-width: 305px;
      transition: all 0.7s !important;
      max-height: 165px;

      @media (min-width: 600px) {
        width: ${props => (props.destinations ? '305px !important' : '100%')};
        height: 163px;
      }

      @media (min-width: 1000px) {
        width: 100% !important;
        height: 163px;
      }

      &:hover {
        transform: scale(1.2)
      }
    }

    ul {
      padding-left: 0;
      margin-bottom: 0;

      @media (min-width: 1200px) {
        margin-bottom: 20px;
      }
    }

    a {
      &:hover {
        text-decoration: none !important;
      }
    }

    li {
      margin: 0.1em;
      list-style: none;
      font-size: 14px;
      font-weight: 300;
      color: ${props => (props.fontColor)};
    }

    h4 {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 9px;
      margin-top: 0;

      @media (min-width: 1200px) {
        font-size: 18px;
      }
     }

    .card-body {
      color: ${props => (props.fontColor)};
      padding-bottom: 0;
      padding-left: 0;
      padding: ${props => (props.destinations && '0')};
      padding-top: 15px;
      font-family: 'Open Sans', sans-serif;
      font-weight: 300;
      line-height: 20px;
      width: ${props => (props.destinations ? '305px' : 'auto')};

      h2, h3,h4 {
        margin-bottom: 0.5em;
        margin-top: 0;
      }

      h3 {
        font-weight: 500;
      }

      p {
        font-weight: 300
      }

      @media (min-width: 600px) {
        padding-top: ${props => (props.destinations ? '15px' : '0')};
        padding-left: ${props => (props.destinations ? '0' : '15px')};
        width: ${props => (props.destinations && '100%')};
      }

      @media (min-width: 1200px) {
        padding-top: 15px;
        padding-left: 0;
        max-width: 305px;
      }
    }
  }
`;

export const StyledInput = styled.input`
  width: ${props => (props.width ? props.width : 'auto')};
  height: ${props => (props.height ? props.height : 'auto')};
  border: none;
  padding: 6px 12px;
`;