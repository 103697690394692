/**
 * This function creates a link HTML element for each hreflang object for CH domain.
 * 
 * @param {filteredAlternateLanguages} array
 * @param {isSwissDomain} bool
 * @param {hostname} string

 * @returns {array} 
 */

import React from 'react';

export function createHrefLangLinksForSwissLanguages(filteredAlternateLanguages, isSwissDomain, hostname) {
  if (filteredAlternateLanguages) {
   const hrefLangLinks = filteredAlternateLanguages
      .filter(el => el !== null)
      .map(el => {
        if (isSwissDomain == "true") {
          if (el.path) {
            let fullPath; 
            let finalPath;

            if (el.path === "/") fullPath = `https://${hostname}.ch`
            else fullPath = `https://${hostname}.ch/` + el.path
      
            if (el.hreflang) {
              const countryCode = el.hreflang.substring(0, el.hreflang.indexOf('-') + 1) + el.hreflang.substring(el.hreflang.indexOf('-') + 1).toUpperCase();

              if (fullPath && fullPath.endsWith("/")) finalPath = fullPath.slice(0, fullPath.lastIndexOf("/"))
              else finalPath = fullPath

              if (finalPath.endsWith("/")) finalPath = finalPath.slice(0, finalPath.lastIndexOf("/"))

              return (
                <link
                  rel="alternate"
                  href={finalPath}
                  key={fullPath}
                  hreflang={countryCode}
                />
              )
            }

          }
        } else {
          if (
            el.hreflang === "fr-ch" ||
            el.hreflang === "en-ch"
          ) {
            
            let path 

            if (el.type === "homepage") {
              if (el.hreflang === "fr-ch") path = "fr/"
              else path = ""
            } else {
              if (el.hreflang === "fr-ch") path = el.path && el.path.replace("private-jet-hire", "vol-prive").replace(".html", "")
              else path = el.path && el.path.replace(".html", "").replace("vol-prive", "private-jet-hire")
            }

            const countryCode = el.hreflang.substring(0, el.hreflang.indexOf('-') + 1) + el.hreflang.substring(el.hreflang.indexOf('-') + 1).toUpperCase();
            let fullPath = `https://${hostname}.ch/` + path

            if (fullPath.endsWith("/")) fullPath = fullPath.slice(0, fullPath.lastIndexOf("/"))

            return (
              <link
                rel="alternate"
                href={fullPath}
                key={fullPath}
                hreflang={countryCode}
              />
            )
          }
        }
    })

    return hrefLangLinks;
  }
}