import React from "react";
import PropTypes from 'prop-types';

import InternalLink from 'components/links/internalLink';

import { StyledHeading, StyledTileWrapper, StyledArticleContainer, StyledFooterArticleWrapper } from "../styled";

const isBlog = process.env.BUILD_BLOG;
const privateflyDomain = process.env.LOCALE_ORIGIN; 

const ArticleSection = ({ footerData, language, preview, width}) => {

  let readMoreLink;

  const linkUrl = new URL(footerData.article_link.url);
  const footerArticleTileWidth = width ? width : '30%';

  if (linkUrl.host.includes(".privatefly.")) {
    if(isBlog) {
      readMoreLink = (
        <a href={`${privateflyDomain}${linkUrl.pathname}`} target={footerData.article_link.target}>
          {footerData.article_button_text}
        </a>
      )
    } else {
      readMoreLink = (
        <InternalLink
          path={footerData.article_link.url} 
          language={language} 
          linkText={footerData.article_button_text}
        />
      )
    }
  } else {
    readMoreLink = (
      <a
        rel={footerData.is_nofollow_link ? "nofollow" : ""} 
        href={footerData.article_link.url}
        target={footerData.article_link.target}
      >
        {footerData.article_button_text}
      </a>
    )
  }
  
  const footerArticleText = preview ? footerData.article_section_text[0].text : footerData.article_section_text.text 
  
  return (
    <StyledTileWrapper width={footerArticleTileWidth} padding="15px 0px">

      <StyledHeading marginBottom="11px"> 
        {footerData.article_heading} 
      </StyledHeading>

      <StyledArticleContainer>    
        <StyledFooterArticleWrapper>

          <img className="footer-article-img" src={footerData.logo.url} /> 
          
          <p className="footer-article-text"> {footerArticleText} </p> 

          {readMoreLink}

        </StyledFooterArticleWrapper>
      </StyledArticleContainer>

    </StyledTileWrapper>
  );
};

ArticleSection.propTypes = {
  footerData: PropTypes.object, 
  language: PropTypes.string,
  preview: PropTypes.bool, 
  width: PropTypes.string
}

export default ArticleSection;
