import React, { useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'components/mediaQuery'

import DesktopLanguageList from 'components/footer/LanguageSelect/DesktopLanguageList';
import MobileLanguageList from 'components/footer/LanguageSelect/MobileLanguageList';

import {
  StyledTileWrapper,
  StyledOverlay
} from "../styled";

import { setFlag } from 'components/footer/LanguageSelect/functions/setFlag';

const FlagSection = ({ footerData, language, location, alternateLanguages, preview, defaultFlag }) => {
  const [ displayLangSelect, setLangSelectPopup ] = useState(false);
  const [ countryFlag, setCountryFlag ] = useState(null);

  useEffect(() => {
    setLangSelectPopup(false);
    setFlag(footerData, setCountryFlag)

    // window.addEventListener('popstate', onBackButtonEvent);
    window.addEventListener('mousedown', handleClickOutside);

    return () => {
      // window.removeEventListener('popstate', onBackButtonEvent); 
      window.removeEventListener('mousedown', handleClickOutside); 
    };
  }, [])

  const handleMenuOpen = () => {
    setLangSelectPopup(!displayLangSelect)
  }

  const handleClickOutside = (e) => {
    if (e.target) {
      if (e.target.id) {
        if (e.target.id === "navigation-overlay") {
          setLangSelectPopup(false)
        } 
      }
    }
  }

  return (
    <StyledTileWrapper width='16%' padding="27px 0px">
      {displayLangSelect ? <StyledOverlay id="navigation-overlay" style={{top: '355px'}} /> : null}  

      <MediaQuery 
        mobileComponent={
          <MobileLanguageList 
            footerData={footerData}
            countryFlag={countryFlag}
            defaultFlag={defaultFlag}
            preview={preview}
            location={location}
            language={language}
            alternateLanguages={alternateLanguages}
          />
        }
        desktopComponent={
          <DesktopLanguageList 
            footerData={footerData}
            countryFlag={countryFlag}
            defaultFlag={defaultFlag}
            preview={preview}
            location={location}
            language={language}
            alternateLanguages={alternateLanguages}
            displayLangSelect={displayLangSelect}
            handleMenuOpen={handleMenuOpen}
          />
        }
        viewPort={1000}
      />
    </StyledTileWrapper> 
  );
};

FlagSection.propTypes = {
  alternateLanguages: PropTypes.array,
  defaultFlag: PropTypes.object,
  footerData: PropTypes.object, 
  language: PropTypes.string,
  location: PropTypes.object,
  preview: PropTypes.bool
}

export default FlagSection;
