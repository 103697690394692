import React from 'react'
import PropTypes from 'prop-types'
import { H1 } from 'styled'

function Heading1 ({ children, richText }) {
  return (
    <React.Fragment>
      <H1
        fontSize='30px'
        fontWeight="400"
        marginTop="0"
        // richText={richText}
      >
        {children}
      </H1>
    </React.Fragment>
  )
}

export default Heading1

Heading1.propTypes = {
  children: PropTypes.array,
  richText: PropTypes.bool
}
