const CheckCenteredText = (element) => {
   let isCentered = false;
   
   if (element.spans.length > 0) {
      element.spans.map(el => {
         if (el?.data?.label === "center-text") {
            isCentered = true
         }
      })
   }
   
   return isCentered
};

export default CheckCenteredText;
