export default function filterPrismicDataByLanguage(prismicData, lang) {

  const filteredData = prismicData
    .find(el => {
      if (process.env.IS_SWISS_DOMAIN == "true") {
        if (lang === "fr-fr") {
          return el.node.lang === "fr-ch"
        } else if (lang === "en-gb") {
          return el.node.lang === "en-ch"
        } else {
          return el.node.lang === lang
        }
      } else {
        return el.node.lang === process.env.LANG_CODE
      }
    })

    if (filteredData) return filteredData
    else {
      
      if (process.env.IS_SWISS_DOMAIN == "true") {
        let data;
        
        if (lang === "en-gb") {
          data = prismicData.find(el => el.node.lang === "en-gb")
        }
        else if (lang === "fr-fr") {
           data = prismicData.find(el => el.node.lang === "fr-fr")
        }

        if (!data) {
           return prismicData
          .find(el => el.node.lang === "en-gb")
        } else {
          return data
        }
        
      } else {
        return prismicData
          .find(el => el.node.lang === "en-gb")
      }
      
    }
}