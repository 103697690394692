   
/**
 * This function creates an hreflang paths for each language code (domain) on EN-GB domains.
 * 
 * @param {hostname} string
 * @param {countryTLD} string 
 * @param {pathname} string 
 * @param {genericAirfield} bool 
 * @param {alternateLanguages} array 
 * @param {item} object 
 * @returns {array} 
 */

 export const createEnglishHrefLangLinks = (
  hostname, 
  countryTLD, 
  pathname, 
  genericAirfield, 
  alternateLanguages,
  item
) => {
  let path;

  switch (item) {
    case 'en-gb':
      path = `https://${hostname}.com${pathname}`
      break
    case 'en-ch':
      path = ""
      break
    case 'fr-ch':
      path = ""
      break
    case 'fr-fr':
      if (process.env.LANG_CODE === "fr-fr") {
        path = `https://${hostname}.fr${pathname}`
      } else {
        if (genericAirfield) {
          path = `https://${hostname}.fr${pathname.replace("private-jet-hire", "vol-prive").replace(".html", "")}` 
        }
        else {
          const findFrenchPath = alternateLanguages.find(el => el.lang === "fr-fr" && el.path)
        
          if (findFrenchPath) {
            const newFrenchPath = findFrenchPath.path.replace("fr/", "").replace(".html", "")
        
            if (findFrenchPath.type === "airfield") {
              path = `https://${hostname}.fr/${findFrenchPath.path.replace("private-jet-hire", "vol-prive").replace("fr/", "").replace(".html", "")}` 
            } else path = `https://${hostname}.fr/${findFrenchPath.type === "home" ? "" : newFrenchPath}`
          } else {
            path = ""
          }
        }
      }
        
        break
    default:
      path = `https://${hostname}.${countryTLD}${pathname}`    
  }

  return path;
}