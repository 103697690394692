import React from 'react';

const activeEnv = process.env.ACTIVE_ENV;

export function loadPrismicScriptForPreview() {
  if (activeEnv === "qa" || activeEnv === "uat") {
    return (
      <script type="text/javascript" src="https://static.cdn.prismic.io/prismic.min.js?new=true" />
    )
  } else {
    return null
  }
}