import React from 'react';
import PropTypes from 'prop-types';
import InternalLink from 'components/links/internalLink';

import { StyledLogoWrapper } from '../styled';

const isBlog = process.env.BUILD_BLOG;
const host = process.env.LOCALE_ORIGIN;

const Logo = ({ logoImage, logoImageAltText, language }) => { 
  return (
    <StyledLogoWrapper>
      { isBlog ? (
        <a href={`${host}`} style={{textDecoration: 'none'}}>
          <img src={logoImage} alt={logoImageAltText} />
        </a>
      ) : (
        <InternalLink
          language={language}
          path="/"
          linkComponent={ <img src={logoImage} alt={logoImageAltText} /> } 
        /> 
      )
    }
    </StyledLogoWrapper>
  )
};

export default Logo;

Logo.propTypes = {
  language: PropTypes.string,
  logoImage: PropTypes.string,
  logoImageAltText: PropTypes.string
};
