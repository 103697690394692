/**
 * This function creates pathnames based on a language code from footer component. It is used on every domain except ch and fr
 * @author Andrew Bedziak
 * 
 * @param {item} object 
 * @param {pathname} string
 * @param {countryCode} string 
 * @param {hostname} string 

 * @returns {string} 
 */

export const createFlagLinksForOtherDomains = (item, pathname, countryCode, hostname, alternateLanguages) => {
  let domainPathname;
  let frenchPath='';

  // temp solution to fix alternate lang links for french domains
  if (alternateLanguages?.length > 0) {
    alternateLanguages.forEach(el => {
      if (el.lang === "fr-fr" && el.path) {
        frenchPath = el.path.replace('.html', '').replace('fr/', '');
      }
    })
  }

  switch (item.country_code) {
    case 'en-gb':
      domainPathname = `https://${hostname}.com${pathname}`
      break
    case 'en-ch':
      domainPathname = `https://${hostname}.ch${pathname.replace('.html', '')}`
      break
    case 'fr-fr':
      domainPathname = `https://${hostname}.fr/${frenchPath}`
      break
    case 'fr-ch':
      domainPathname = `https://${hostname}.ch/fr/${frenchPath}`
      break
    default:
      domainPathname = `https://${hostname}.${countryCode}${pathname}`
  }

  return domainPathname
}