import React from 'react';
import PropTypes from 'prop-types';

import LangList from 'components/footer/LanguageSelect/LangList.js';

import {
  StyledHeading,
  DesktopLangSelectPopup,
  StyledLangSelectDesktop
} from "../styled";

function DesktopLanguageList({
  footerData, 
  countryFlag, 
  defaultFlag, 
  preview, 
  location, 
  language, 
  alternateLanguages,
  displayLangSelect,
  handleMenuOpen
}) {
  return (
    <React.Fragment>
      <DesktopLangSelectPopup isActive={displayLangSelect}>
        <img 
          onClick={handleMenuOpen} 
          className="lang-select-close-icon"
          src={footerData.language_select_close_icon.url}
        />
        <LangList 
          preview={preview}
          location={location}
          language={language}
          languagesData={footerData.language}
          alternateLanguages={alternateLanguages}
        />
      </DesktopLangSelectPopup>
      <StyledHeading marginBottom="23px"> 
        {footerData.footer_language_section_heading} 
      </StyledHeading>
      <div style={{display: 'inline-flex'}}>
        <img 
          src={countryFlag ? countryFlag.flag_image.url : defaultFlag.flag_image.url}
          width="30"
          height="20"
        />
        <StyledLangSelectDesktop onClick={handleMenuOpen}>
          { countryFlag ? countryFlag.language_name : defaultFlag.language_name } 
          <i className="lang-select-arrow_down" />
        </StyledLangSelectDesktop> 
      </div>
    </React.Fragment>
  )
}

DesktopLanguageList.propTypes = {
  alternateLanguages: PropTypes.array,
  countryFlag: PropTypes.object,
  defaultFlag: PropTypes.object,
  displayLangSelect: PropTypes.bool,
  footerData: PropTypes.object, 
  handleMenuOpen: PropTypes.func, 
  language: PropTypes.string, 
  location: PropTypes.object, 
  preview: PropTypes.bool, 
}

export default DesktopLanguageList
