import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Logo from './logo';
import Menu from './Menu';
import DropdownMenu from './dropdown';
import Login from './login';

import { StyledHeaderNav, StyledPrimaryMenuWrapper, StyledPhoneWrapper, StyledHamburgerMenu, StyledHeaderIconWrapper} from './styled';

const gtmCode = process.env.GTM_CODE;

// 96824 - this file incl preview changes

const Desktop =({
  menuData,
  preview,
  phoneNumber,
  errorMessages,
  previewHeaderData,
  language,
  loggedIn,
  logoutFunc,
  location,
}) => {

  const [ displayMenuDropdown, setDropdown ] = useState(false);

  const handleMenuOpen = () => {
		setDropdown(!displayMenuDropdown)
	}

  const isFR = process.env.LANG_CODE === 'fr-fr' || language === 'fr-fr';

  const HamburgerMenuIcon = displayMenuDropdown ? menuData.data.close_menu_icon.url : menuData.data.open_menu_hamburger_icon.url;
	const HamburgerMenuText = displayMenuDropdown ? (isFR ? 'FERMER' : 'CLOSE') : 'MENU';

  const phoneNo = preview ? '+44 (0)20 7100 6960' : phoneNumber;
  const searchErrorMsg = preview ? 'search box is empty' : errorMessages.required_information_mesage;

  const hasRulertel = gtmCode === "GTM-NJ3MRXX" || gtmCode === "GTM-WTMCFMK";
  const isJetCardPage = location.pathname.includes("jet-card");
  const rulerPhoneNumber = hasRulertel && isJetCardPage ? phoneNumber : !hasRulertel ? phoneNumber : null;
  // On pages such as Jet Card where phone number comes from Prismic remove the (0) from the phone number 102990
  const hrefPhoneNumber = rulerPhoneNumber && rulerPhoneNumber.replace(/\+44\s\(0\)/g, '+44');

  return (
    <StyledHeaderNav className="header">
        
      <Logo 
        logoImage={menuData.data.header_logo.url} 
        logoImageAltText={menuData.data.header_logo.alt || ""}
        language={language}
      />

      <StyledPrimaryMenuWrapper>

        <Menu 
          menuOpen={displayMenuDropdown}
          menuData={menuData}
          previewHeaderData={previewHeaderData}
          language={language}
          location={location}
        />

        <StyledPhoneWrapper>
          <p className={`header-phone-text ${rulerPhoneNumber === null && 'fadein-text'}`}>{menuData.data.contact_text}</p>
          <a 
            className={`rulertel header-phone-number ${rulerPhoneNumber === null && 'fadein-phone'}`} 
            href={`tel:${hrefPhoneNumber}`}
          > 
            { rulerPhoneNumber } 
          </a>
        </StyledPhoneWrapper>

        <Login 
          username={loggedIn}
          logoutFunc={logoutFunc}
          language={language}
          userIcon={menuData.data.user_login_icon.url}
          loginText={menuData.data.login_form_button_text}
          myProfileText={menuData.data.my_profile_text}
          logoutText={menuData.data.logout_text}
        /> 

        <StyledHamburgerMenu onClick={handleMenuOpen}>
          <StyledHeaderIconWrapper> <img alt="menu" src={HamburgerMenuIcon} /> </StyledHeaderIconWrapper>
          <p className="hamburger-icon-text" > {HamburgerMenuText} </p>
        </StyledHamburgerMenu>

        <DropdownMenu
          menuData={menuData}
          displayMenuDropdown={displayMenuDropdown}
          setDropdown={setDropdown}
          searchErrorMessage={searchErrorMsg}
          language={language}
          preview={preview}
          location={location}
        />

      </StyledPrimaryMenuWrapper>
        
    </StyledHeaderNav>
  )
}

export default Desktop

Desktop.propTypes = {
  errorMessages: PropTypes.object,
  language: PropTypes.string,
  location: PropTypes.object,
  loggedIn: PropTypes.object,
  logoutFunc: PropTypes.func,
  menuData: PropTypes.object,
  phoneNumber: PropTypes.string,
  preview: PropTypes.bool,
  previewHeaderData: PropTypes.object,
}
