/**
 * This function creates a link HTML element for each hreflang object for en-gb and fr domains.
 * 
 * @param {hrefLangUrls} array

 * @returns {array} 
 */

import React from 'react';

export function createHrefLangLinks(hrefLangUrls) {
  if (hrefLangUrls) {
    const hrefLangLinks = hrefLangUrls.map(el => {
      let finalPath

      if (el.path && el.path.endsWith("//")) {

        finalPath = el.path.slice(0, el.path.lastIndexOf("/") - 1)
      } else if (el.path && el.path.endsWith("/")) {
        finalPath = el.path.slice(0, el.path.lastIndexOf("/"))
      } 
      else finalPath = el.path

      return (
        <link
          rel="alternate"
          href={finalPath}
          key={el.path}
          hreflang={el.code}
        />
      )
    })

    return hrefLangLinks;
  } 
}