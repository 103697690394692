import styled from 'styled-components';
import { Link } from 'gatsby';

export const StyledRichTextImage = styled.img`
  display: flex;
  max-width: 100%;
  padding-bottom: 1em;
  margin: auto;

  @media (min-width: 600px) {
    float: ${props => props.className == 'right-align' ? 'right' : props.className == 'left-align' ? 'left' : 'none'};
    padding-right: ${props => props.className === 'left-align' && props.width > 50 ? '2em' : 0};
    padding-left: ${props => props.className === 'right-align' ? '2em' : 0};
  }
`;

export const StyledImageCaption = styled.figcaption`
  font-size: 11px;
  line-height: normal;
  padding: 30px 0px 0px 0px;
  margin-top: -3px;
  color: #46344E;
  text-align: center;
`;

export const PlaceholderImage = styled(StyledRichTextImage)`
  max-width: 400px;
  display:flex;
  float: ${props => (props.className === 'right-align' ? 'right' : props.className === 'left-align' ? 'left' : 'none')};
  width: ${props => (props.className === 'right-align' || props.className === 'left-align' ? '200px' : 'unset')};
  height: ${props => (props.className === 'right-align' || props.className === 'left-align' ? '120px' : 'unset')};
`;

export const EmbedStyle = styled.div`
  position: relative;
  padding-bottom: 76.25%;
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @media (min-width: 720px) {
    padding-bottom: 56.25%;
    
    iframe {
      top: 5%;
      width: 90%;
      height: 90%;
    }
  }
`;

export const StyledEmbed = styled.div`
  margin-bottom: 20px;
`

export const ImgWrap = styled.section`
  position: relative;
  text-align: center;
`

export const Copyright = styled.small`
  position: relative;
`

export const StyledAnchor = styled.a`
  color: #4990e2;

  :hover{
    text-decoration: underline;
    color: #23527c;
  }
`

export const GatsbyLink = styled(Link)`
  color: #4990e2;

  :hover{
    text-decoration: underline;
    color: #23527c;
  }
` 
export const Unpreformatted = styled.div`
  margin-bottom: ${props => (props.isTable && '20px')};
  
  @media (max-width: 520px) {
    overflow: ${props => (props.isTable && 'hidden')};
    overflow-x: ${props => (props.isTable && 'scroll')};
  }
`