/**
 * This function checks alternate versions of the current path
 * @author Andrew Bedziak
 * 
 * @param {alternateLanguages} array - Array of alternate versions of each page
 * @param {language} string - Language of the current page. 

 * @returns {object, boolean} 
 */

export const alternateLanguagesCheck = (alternateLanguages, language) => { 
    const checkIfSwissFrenchHasEnglishAlternate = alternateLanguages.find(el => {
      if (language === "fr-fr" && process.env.IS_SWISS_DOMAIN === "true") if (el.lang === "en-gb" || el.lang === "en-ch") return el
      else return null
    })

    const checkIfSwissEnglishIsUnique = alternateLanguages.length == 1 && alternateLanguages.every(el => {
      if (language === "en-gb") if (el.lang === "en-ch") return el
    })
    
    const checkIfSwissFrenchIsUnique = alternateLanguages.length == 1 && alternateLanguages.every(el => {
      if (language === "fr-fr") if (el.lang === "fr-ch") return el
    })

    let checkIfFrenchIsUniqueOnSwissDomain = alternateLanguages && alternateLanguages.every(el => {
      if (language === "fr-fr") if (el.lang === "fr-fr") return el
    })

    if (checkIfFrenchIsUniqueOnSwissDomain) {
      checkIfFrenchIsUniqueOnSwissDomain = alternateLanguages && alternateLanguages.find(el => {
        if (language === "fr-fr") if (el.lang === "fr-fr") return el
      })
    }

    const checkIfFrenchHasEnglishAlternate = alternateLanguages.find(el => {
      if (language === "fr-fr" || process.env.LANG_CODE === "fr-fr") if (el.lang === "en-gb" || el.lang === "en-ch") return el
    })

    const checkIfEnglishHasFrenchAlternate = alternateLanguages.some(el => {
      if (language === "en-gb") if (el.locale === "fr-fr" || el.lang === "fr-ch") return el
    })

    const checkIfEnglishHasFrenchAlternatePath = alternateLanguages.find(el => {
        if (language === "en-gb" && el.lang === "fr-fr") return el
    })

    const checkIfFrenchIsUnique = alternateLanguages && alternateLanguages.every(el => {
      if (process.env.LANG_CODE === "fr-fr") if (el.lang === "fr-fr") return el
    })

    return {
      checkIfSwissEnglishIsUnique,
      checkIfSwissFrenchIsUnique,
      checkIfFrenchHasEnglishAlternate,
      checkIfEnglishHasFrenchAlternate,
      checkIfFrenchIsUnique,
      checkIfSwissFrenchHasEnglishAlternate,
      checkIfEnglishHasFrenchAlternatePath,
      checkIfFrenchIsUniqueOnSwissDomain
    }
}
