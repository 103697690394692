import React from 'react'

const getSvgIcon = (isActive) => {
  return (
    <i style={{fontSize: '25px', padding: '10px'}}>
      {isActive === 0 || isActive ? <span>&minus;</span> : <span>&#43;</span>}
    </i>
  );
};

const itemIcon = (props) => getSvgIcon(props);

export { itemIcon }