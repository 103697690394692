import styled from 'styled-components'
import { Link } from 'gatsby';
import InternalLink from 'components/links/internalLink';

export const StyledMobileHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-self: center;

  @media (min-width: 1024px) {
    display: none;
    opacity: 0;
  }
`

export const StyledMobileHamburgerMenu = styled.img`
  display: flex;
  max-width: 24px;
  max-height: 24px;
  align-self: center;
  position: absolute;
  right: 16px;
  top: 20px;
  padding: 4px 0;
  cursor: pointer;
`;

export const StyledSubMenuNavbar = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 17px 0 34px 0;
`

export const StyledCloseIcon = styled.div `
  max-width: 24px;
  max-height: 24px;
  padding: 3px;
  align-self: center;
  cursor: pointer;

  img { max-height: 18px; }
`

export const StyledForm = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 1000px) {
    align-items: flex-start;
    width: auto;
  }
`

export const StyledMenu = styled.nav`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  z-index: 10001;
  // transform: ${props => props.isMenuOpen ? "translateY(0)" : "translateY(-100%)"};
  // transition: transform 300ms ease-in-out; 

  && {
    display: ${props => props.isMenuOpen ? "flex" : "none"};
    flex-direction: column;
    align-items: flex-start;
    justify-content: baseline;
    background: #D4D6DE;
    box-shadow: none;
    margin-top: 0;

    li {
      line-height: 25px;
    }
  }

  .navigation-wrapper {
    height: 100vh;
    width: 100%;
    overflow-x: hidden;
    padding: 0 26px 0 26px;

    ::-webkit-scrollbar {
      display: none;
    }
  }
`

export const StyledLoginWrapper = styled.div`
  padding: 15px 0;
  border-top: 1px solid rgba(60,60,59,0.2);

  a {
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.93px;
    text-transform: uppercase;
    color: #3C3C3B;

    &:hover {
      text-decoration: underline;
    }
  }

  .login {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logged-in {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .user-icon {
    max-height: 24px;
  }
`;

export const StyledMenuItem = styled.li`
  && {
    text-transform: uppercase;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    width: 100%;
    cursor: pointer;

    &:hover {
      background-color: #D4D6DE !important;
    }

    a {
      color: #3C3C3B;
      font-size: 13px;
    }

    @media (min-width: 768px) {
      &:last-of-type {
        height: auto;
        width: auto;
      }

      a:hover {
        color: #e30613;
      }
    }
  }
`

export const StyledSubMenu = styled.div`
  .submenu-title-link {
    line-height: 1.5em;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
    cursor: pointer;
    background: #D4D6DE;
    transition: background 500ms;

    a { 
      color: #3C3C3B;
      &:hover { text-decoration: none; }
    }
  }

  &&& {
    font-family: 'Open Sans', sans-serif;
    list-style-type: none;
    transition: 0.7s;
    background: #D4D6DE;
    flex-grow: 1;
    width: 100%;
    color: #3C3C3B;
    text-decoration: none;
    border-top: 1px solid rgba(60, 60, 59, 0.2);
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.93px;
  }
`

export const Input = styled.input`
  width: 100%;
  height: 45px;
  padding: 6px 12px;
  margin-top: 39px;
  margin-bottom: 69px;
  border: 1px solid #B4B5B4;
  outline-width: thin;
`

export const StyledLink = styled(Link)`
  color: #3C3C3B;
  font-size: 13px;

  &:hover {
    color: #3C3C3B;
    text-decoration: underline;
  }
`

export const StyledInternalLink = styled(InternalLink)`
  color: #3C3C3B;
  font-size: 13px;

  &:hover {
    color: #3C3C3B;
    text-decoration: underline;
  }
`

export const StyledFlagLink = styled.a`
  color: #e30613;
  font-size: 14px;

  &:hover {
    color: #e30613;
    text-decoration: underline;
  }
`

export const StyledLi = styled.li`
  width: 100%;
  margin-bottom: 0.5em;
  font-weight: 300;

  .active-path {
    font-weight: bold;
    text-decoration: underline;
  }
`

export const StyledIcon = styled.i`
  display: flex;
  justify-content: center;
  align-items: center;

  &::before {
    color: #e30613;
    font-size: 2em;
    margin-right: 0.5em;
  }
`

export const StyledSublist = styled.ul`
  max-height: ${props => props.isActive ? "500px": 0};
  padding-left: 0;
  margin-top: ${props => props.isActive ? "5px": "0"};
  margin-bottom: ${props => props.isActive ? "12px": "0"};
  list-style: none;
  overflow: hidden;
  transition: all 500ms;
`

export const StyledExternalLink = styled.a`
  color: #3C3C3B;
  font-size: 14px;

  &:hover {
    color: #3C3C3B;
    text-decoration: underline;
  }
`

export const StyledLogoWrapper = styled.div`
  // display: block;
  // align-items: center;

  width: 100%;
  height: 100%;
  max-width: 134px;
  max-height: 29px;


  // .mobile-logo {
  //   width: 100%; 
  //   max-height: 23.75px; 
  //   margin-top: 5px;
  // }
`

export const StyledPhoneNumber = styled.a`
  font-size: 18px;
  font-weight: 500;
  font-family: 'Raleway', sans-serif;
  color: #000000;
  cursor: pointer;
  letter-spacing: -0.23px;
  line-height: 20px;

  &:hover {
    color: #3C3C3B;
  }
`

export const Paragraph = styled.p`
  color: #636463;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.93px;
  line-height: 11px;
`

export const StyledContactWrapper = styled.div`
  display: flex; 
  flex-direction: column; 
  text-align: center;
  padding: 25px 0px;
  border-top: 1px solid rgba(60,60,59,0.2);
  border-bottom: 1px solid rgba(60,60,59,0.2);
`;