import styled from 'styled-components'

export const StyledFxAirBanner = styled.div`
  height: 70px;
  background: white;
  border: 2px solid black;

  .wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    max-width: 1336px;
    margin: auto;
    padding: 0 40px;
    align-items: center;

    @media (max-width: 1024px) {
      justify-content: center;
    }
  }
`

export const StyledFxAirBannerImg = styled.a`
  max-width: 100%;
  height: inherit;
  padding: 15px 0px 15px 10px;

  img {
    height: inherit;
  }

  @media (max-width: 1200px) {
    padding: 20px 0px 15px 10px;
  }

  @media (max-width: 1024px) {
    display: none;
  }
`

export const StyledFxAirBannerText = styled.div`
  font-weight: bold !important;

  p {
    font-size: ${props => props.isFR ? "20px !important": "22px !important"};
    margin: 0 !important;
    color: black !important;

    a {
      color: black !important;
      text-decoration: underline !important;
    }

    @media (max-width: 1440px) {
      font-size: ${props => props.isFR ? "18px !important": "20px !important"};
    }

    @media (max-width: 1200px) {
      font-size: ${props => props.isFR ? "16px !important": "17px !important"};
    }
  } 
`;