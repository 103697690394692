import React from 'react';
import PropTypes from 'prop-types';

import InternalLink from 'components/links/internalLink';

import { StyledFooterTermLinks, StyledFooterDiv, StyledCopyrightText } from '../styled';

const TermsLinks = ({ footerData, language }) => {
  const isBlogCheck = process.env.BUILD_BLOG;
  const privateflyDomain = process.env.LOCALE_ORIGIN;

  const currentYear = new Date().getFullYear();
  const footerCopyrightText = footerData.copyright_text.replace('{{year}}', currentYear);

  const footerLinks = footerData.footer_links.map(el => {   
    let parsedUrl = new URL(el.footer_links_url.url)
    const key = parsedUrl.pathname
    const text = el.footer_links_text
    
    return ( 
      isBlogCheck ? 
        <a key={key} href={`${privateflyDomain}${parsedUrl.pathname}`}>
          {text}
        </a> 
      : 
        <InternalLink 
          key={key}
          language={language} 
          path={parsedUrl.pathname} 
          linkText={text}
        />
    )
  });

  return (
    <StyledFooterDiv>
      <StyledCopyrightText> {footerCopyrightText} </StyledCopyrightText>
      <StyledFooterTermLinks> {footerLinks} </StyledFooterTermLinks>
    </StyledFooterDiv>
  );
};

TermsLinks.propTypes = {
  footerData: PropTypes.object,
  language: PropTypes.string,
}

export default TermsLinks;
