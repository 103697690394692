import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InternalLink from 'components/links/internalLink';

import LinkList from './dropdown/LinkList';
import { itemIcon } from './utils/icons';
import { StyledSubMenu, StyledSublist } from './styled';

const isBlogBuild = process.env.BUILD_BLOG;
const privateflyDomain = process.env.LOCALE_ORIGIN;

function SubMenu({ menuItem, index, preview, language, location }) {

  const [ activeList, setActiveList ] = useState(null);

  const handleExpandMobileMenu = (e, index) => {
    setActiveList(activeList => {
      if (activeList !== index) return index
    })
  }

  const url = menuItem.primary.menu_title_url && new URL(menuItem.primary.menu_title_url.url);
  const menuTitleText = preview ? menuItem.primary.menu_title[0].text : menuItem.primary.menu_title.text;

  const menuHeadings = (
    <div className="submenu-title-link">

      { isBlogBuild ? <a href={`${privateflyDomain}${url.pathname}`}> {menuTitleText} </a> : (
        <InternalLink 
          path={url.pathname}
          linkText={menuTitleText}
          language={language}
        >
          <span> {menuTitleText} </span>
        </InternalLink>
        )
      }

      <div className="arrow-wrapper" > {itemIcon(activeList)} </div>

    </div>
  )

  return (
    <StyledSubMenu 
      key={menuItem.primary.menu_title.text} 
      onClick={(e) => handleExpandMobileMenu(e, index)}
    >

      {menuHeadings}

      <StyledSublist 
        isActive={activeList === index ? true : false}
        className={activeList === index ? `active-${index}` : "not-active"}
      >
        <LinkList 
          isActive={activeList === index ? true : false}
          sublist={menuItem.items} 
          language={language}
          location={location}
        />
      </StyledSublist>
    </StyledSubMenu>
  )
}

SubMenu.propTypes = {
  index: PropTypes.number,
  language: PropTypes.string,
  location: PropTypes.object,
  menuItem: PropTypes.object,
  preview: PropTypes.bool,
}

export default SubMenu

