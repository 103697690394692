/**
 * This function an hreflang property to every object in alternateLanguages array
 * 
 * @param {alternateLanguages} array - Array of alternate versions of each page
 * @param {isSwissDomain} bool 

 * @returns {array} 
 */

export const addHreflangsToAlternateLanguages = (alternateLanguages, isSwissDomain) => {
  if (alternateLanguages) {
    const altLangsWithHrefLang = alternateLanguages
      .filter(el => el != null)
      .map(el => {
        let hreflang;
  
        if (isSwissDomain == "true") {
          if (el.locale === "fr-fr") hreflang = "fr-ch"
          else if (el.locale === "en-gb") hreflang = "en-ch"
    
          el.hreflang = hreflang
    
          return el
        } else {
          if (el.lang === "fr-fr" || el.lang === "fr-ch") hreflang = "fr-ch"
          if (el.lang === "en-gb" || el.lang === "en-ch") hreflang = "en-ch"
    
          el.hreflang = hreflang
    
          return el
        }
    })

    return altLangsWithHrefLang

  } else {
    return null
  }
}