import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';

import HeaderWrapper from 'components/HOC/HeaderWrapper';
import Desktop from './DesktopNavbar';
import Mobile from './MobileNavbar';

const isBlog = process.env.BUILD_BLOG;

const Header = ({
  localePhoneNumber, 
  location, 
  errorMessages, 
  previewHeaderData, 
  preview,
  language,
  alternateLanguages,
  headerData
}) => {

  const [loggedIn, setLoggedIn] = useState({ state: false, name:null });

  useEffect(() => {
    getLoggedIn();

    const checkLogin = () => {
      const isCustomerLoggedIn = Cookies.get('user') !== undefined && Cookies.get('user') !== "Not Available";

      if (!isCustomerLoggedIn) {
        localStorage.removeItem('user')
        localStorage.removeItem('userId')
  
        setLoggedIn({
          state: false,
          type: null,
          name: null,
          id: null
        })
      }
    }

    checkLogin()

  }, [])

  const getLoggedIn = () => {
    let isCustomer;
    let isOperator;
    let userLoggedIn;
    let userIdLoggedIn;

    if (isBlog) {
      isCustomer = Cookies.get('user') !== undefined && Cookies.get('user') !== "Not Available";
      isOperator = Cookies.get('operator') !== undefined && Cookies.get('user') !== "Not Available";
    } else {
      isCustomer = localStorage.getItem('user') !== null;
      isOperator = localStorage.getItem('operator') !== null;
    }

    if (isBlog) {
      userLoggedIn = isCustomer ? Cookies.get('user') : isOperator && localStorage.getItem('operator')
      userIdLoggedIn = isCustomer ? Cookies.get('userId') : isOperator && localStorage.getItem('operatorId')
    } else {
      userLoggedIn = isCustomer ? localStorage.getItem('user') : isOperator && localStorage.getItem('operator')
      userIdLoggedIn = isCustomer ? localStorage.getItem('userId') : isOperator && localStorage.getItem('operatorId')
    }

    if (userLoggedIn && userIdLoggedIn) {
      setLoggedIn({
        state: true,
        type: isCustomer ? 'Customer' : isOperator && 'Operator',
        name: userLoggedIn,
        id: userIdLoggedIn,
      }) 
    }
  }

  const logout = () => {
    let isCustomerCookie;
    let isCustomerLocalStorageCookie;
    let isOperator;

    isCustomerCookie = Cookies.get('user') !== undefined && Cookies.get('user') !== "Not Available";
    isCustomerLocalStorageCookie = localStorage.getItem('user') !== null && Cookies.get('user') !== "Not Available";
    isOperator = localStorage.getItem('operator') !== null;

    if (isCustomerCookie) {
      Cookies.remove('user');
      Cookies.remove('userId');
    } 

    if (isCustomerLocalStorageCookie) {
      localStorage.removeItem('user')
      localStorage.removeItem('userId')
    } else if (isOperator) {
      localStorage.removeItem('operator')
      localStorage.removeItem('operatorId')
    }
    
    setLoggedIn({
      state: false,
      type: null,
      name: null,
      id: null
    })
  }

  return (
    <React.Fragment>
      <Desktop
        location={location}
        menuData={headerData}
        preview={preview}
        loggedIn={loggedIn}
        logoutFunc={logout}
        phoneNumber={localePhoneNumber}
        errorMessages={errorMessages}
        previewHeaderData={previewHeaderData}
        language={language}
        alternateLanguages={alternateLanguages}
      />
      <Mobile
        location={location}
        menuData={headerData}
        preview={preview}
        loggedIn={loggedIn}
        logoutFunc={logout}
        phoneNumber={localePhoneNumber}
        errorMessages={errorMessages}
        previewHeaderData={previewHeaderData}
        language={language}
        alternateLanguages={alternateLanguages}
      />
    </React.Fragment>
  )
  
}

export default HeaderWrapper(Header);

Header.propTypes = {
  alternateLanguages: PropTypes.array,
  errorMessages: PropTypes.object,
  headerData: PropTypes.object,
  language: PropTypes.string,
  localePhoneNumber: PropTypes.string,
  location: PropTypes.object,
  preview: PropTypes.bool,
  previewHeaderData: PropTypes.object,
}
