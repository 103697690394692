
const locale = process.env.LANG_CODE;
const isBlogCheck = process.env.BUILD_BLOG;
const suffix = isBlogCheck && locale!== "fr-fr" ? '.html' : process.env.GATSBY_URL_SUFFIX;
const blogHost = process.env.LOCALE_ORIGIN

export const linkResolver = doc => {
  const destinationsCategoryUrl = locale === "fr-fr" ? "vol-prive" : "private-jet-hire";
  const pressReleasesCategoryUrl = locale === "fr-fr" ? "communiques-de-presse" : "press-releases";
  const aircraftCategoryUrl = locale === "fr-fr" ? "jet-prive" : "private-jets";

  const turbopropCategoryUrl = locale === "fr-fr" ? "/louer-un-turbopropulseur" : "/turboprop-hire";
  const lightJetCategoryUrl = locale === "fr-fr" ? "/louer-un-jet-leger" : "/light-jet-hire";
  const midsizeCategoryUrl = locale === "fr-fr" ? "/louer-un-jet-medium" : "/midsize-jet-hire";
  const superMidsizeCategoryUrl = locale === "fr-fr" ? "/louer-un-jet-long-courrier" : "/super-midsize-long-range-jet-hire";
  const heavyJetCategoryUrl = locale === "fr-fr" ? "/louer-un-jet-lourd" : "/heavy-jet-hire";
  const vipAirlinerCategoryUrl = locale === "fr-fr" ? "/louer-un-avion-commercial" : "/vip-airliner-hire";

  if (doc) {
    switch (doc.type) {
      case 'aircraft' : 
        const category = doc.data.aircraft_category;

        switch (category) {
          case 'small props': 
            return `${isBlogCheck && blogHost}/${aircraftCategoryUrl}${turbopropCategoryUrl}/${doc.data.url}${suffix}`
          case 'small jets': 
            return `${isBlogCheck && blogHost}/${aircraftCategoryUrl}${lightJetCategoryUrl}/${doc.data.url}${suffix}`
          case 'medium jets': 
            return `${isBlogCheck && blogHost}/${aircraftCategoryUrl}${midsizeCategoryUrl}/${doc.data.url}${suffix}`
          case 'long range jets': 
            return `${isBlogCheck && blogHost}/${aircraftCategoryUrl}${superMidsizeCategoryUrl}/${doc.data.url}${suffix}`
          case 'regional airliners': 
            return `${isBlogCheck && blogHost}/${aircraftCategoryUrl}${heavyJetCategoryUrl}/${doc.data.url}${suffix}`
          case 'large airliners': 
            return `${isBlogCheck && blogHost}/${aircraftCategoryUrl}${vipAirlinerCategoryUrl}/${doc.data.url}${suffix}`
          default:
            return '/';
        }
      case 'airfield' :
        return `${isBlogCheck && blogHost}/${destinationsCategoryUrl}/${doc.data.url}${suffix}`;
      case 'country' :
        return `${isBlogCheck && blogHost}/${destinationsCategoryUrl}/${doc.data.url}${suffix}`;
      case 'inspirational' :
        return `${isBlogCheck && blogHost}/${doc.data.category}/${doc.data.url}${suffix}`;
      case 'other' :
        return `${isBlogCheck && blogHost}/${doc.data.category ? `${doc.data.category}/` : doc.data.url}${suffix}`;
      case 'press_release' :
        return `${isBlogCheck && blogHost}/${pressReleasesCategoryUrl}/${doc.data.url}${suffix}`;
      // blog urls  
      case 'post' :
        return `${isBlogCheck && blogHost}/${doc.uid}`;
      case 'aircraft_listing' :
        return `${isBlogCheck && blogHost}/${aircraftCategoryUrl}/${doc.data.url}`
      case 'aircraft_home' : 
        const aircraftHomeUrl = locale === 'fr-fr' ? `${isBlogCheck && blogHost}/${doc.data.url}${suffix}` : `${isBlogCheck && blogHost}/${doc.data.url}/${doc.data.url}${suffix}`

        return `${isBlogCheck && blogHost}/${aircraftHomeUrl}`
      case 'about_team' :
        return `${isBlogCheck && blogHost}/${doc.data.category}${isBlogCheck && blogHost}/${doc.data.url}${suffix}`
      case 'country_home' :
        const countriesPageUrl = locale === "fr-fr" ? `${isBlogCheck && blogHost}/${doc.data.url}${suffix}` : `${isBlogCheck && blogHost}/${doc.data.url}/${doc.data.url_path}${suffix}`;

        return `${isBlogCheck && blogHost}/${countriesPageUrl}${suffix}`
      case 'country_listing' :
        const destinationsPageUrl = locale === "fr-fr" ? `${isBlogCheck && blogHost}/${doc.data.url}${suffix}` : `${isBlogCheck && blogHost}/${doc.data.url}/${doc.data.url_path}${suffix}`

        return `${isBlogCheck && blogHost}/${destinationsPageUrl}`
      case 'site_map' :
        return `${isBlogCheck && blogHost}/${doc.data.url}${suffix}`
      case 'site_search' : 
        return `${isBlogCheck && blogHost}/${doc.data.url}${suffix}`
      case 'index_page_empty_legs' :
          return `${isBlogCheck && blogHost}/${doc.data.url_folder}/${doc.data.url}${suffix}`
      case 'inspirational-index-page' :
        const inspirationalIndexPageUrl = locale === 'fr-fr' ? `${doc.data.url}${suffix}` : `${doc.data.url}/${doc.data.url}${suffix}`

        return `/${inspirationalIndexPageUrl}`
      case 'index_page_press_and_media' :
        const pressReleasesUrl = locale === "fr-fr" ? `${isBlogCheck && blogHost}/${doc.data.url}${suffix}` : `${isBlogCheck && blogHost}/${doc.data.url}/${doc.data.url_category}${suffix}`;

          return `${isBlogCheck && blogHost}/${pressReleasesUrl}`
      case 'error_page_index' :
        return `/error/errorhandler` + doc.data.url;
      case 'home' :
          return '/'
      default :
        return '/'
    }
  }
};
