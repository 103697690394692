export default function createProperties(language){
  let properties = {};
  let langCode;
  let langPrefix;

  properties.urls = {}
  properties.aircraftCategories = []

  const locale = process.env.LANG_CODE;
  const isSwissDomain = process.env.IS_SWISS_DOMAIN;
  const suffix = process.env.GATSBY_URL_SUFFIX;
  const isBlog = process.env.BUILD_BLOG;

  if (typeof localStorage !== 'undefined') {
    langCode = localStorage.getItem("langCode") 
  } else {
    langCode = ""
  }
    if (language === "fr-fr") langPrefix = "/fr"
    else language = ""

  if (isSwissDomain == "true") {
    if (language === "fr-fr") {
      properties.urls.estimatePrices = `${langPrefix}/location-jet-prive/estimation-de-prix`
      properties.urls.flightSearch = `${langPrefix}/location-jet-prive`
      properties.urls.newsletterThanks = `${langPrefix}/newsletter/remerciements`
      properties.urls.newsletterJoin = `/newsletter/souscrire`
      properties.urls.customerDashboard = `${langPrefix}/client/votreJournalDeBord`
      properties.urls.logout = `${langPrefix}/client/votreJournalDeBord?logout=true`
      properties.urls.operatorDashboard = `/operator/search_flights`
      properties.urls.operatorLogout = `/operator/search_flights?logout=true`
      properties.urls.login = `${langPrefix}/mon-compte`,
      properties.urls.searchResultsUrl = `${langPrefix}/recherche/recherche`,
      properties.urls.reviews = `/a-propos/temoignage`
    } else {
      properties.urls.estimatePrices = `/private-jet-charter/estimate-prices${suffix}`
      properties.urls.flightSearch = `/private-jet-charter/private-jet-charter${suffix}`
      properties.urls.newsletterThanks = `/privatejet-services/email-newsletter-thanks`
      properties.urls.newsletterJoin = `/privatejet-services/email-newsletter-join`
      properties.urls.customerDashboard = `/customer/customerDashboard${suffix}`
      properties.urls.logout = `/customer/customerDashboard${suffix}?logout=true`
      properties.urls.operatorDashboard = `/operator/search_flights${suffix}`
      properties.urls.operatorLogout = `/operator/search_flights${suffix}?logout=true`
      properties.urls.login = `/login-signup${suffix}`,
      properties.urls.searchResultsUrl = `/search/search`
      properties.urls.reviews = `/about/testimonials`
    }
  } else {
    if (locale === "fr-fr") {
      properties.urls.estimatePrices = `/location-jet-prive/estimation-de-prix`
      properties.urls.flightSearch = `/location-jet-prive`
      properties.urls.newsletterThanks = `/newsletter/remerciements`
      properties.urls.newsletterJoin = `/newsletter/souscrire`
      properties.urls.customerDashboard = `/client/votreJournalDeBord`
      properties.urls.logout = `/client/votreJournalDeBord?logout=true`
      properties.urls.operatorDashboard = `/operator/search_flights`
      properties.urls.operatorLogout = `/operator/search_flights?logout=true`
      properties.urls.login = `/mon-compte`,
      properties.urls.searchResultsUrl = `/recherche/recherche`,
      properties.urls.reviews = `/a-propos/temoignage`
    } else {

      properties.urls.estimatePrices = `/private-jet-charter/estimate-prices${suffix}`
      properties.urls.flightSearch = `/private-jet-charter/private-jet-charter${suffix}`
      properties.urls.newsletterThanks = `/privatejet-services/email-newsletter-thanks${suffix}`
      properties.urls.newsletterJoin = `/privatejet-services/email-newsletter-join${suffix}`

      if (isBlog) {
        properties.urls.customerDashboard = `/customer/customerDashboard.html`
        properties.urls.logout = `/customer/customerDashboard.html?logout=true`
      } else {
        properties.urls.customerDashboard = `/customer/customerDashboard${suffix}`
        properties.urls.logout = `/customer/customerDashboard${suffix}?logout=true`
      }
      
      properties.urls.operatorDashboard = `/operator/search_flights${suffix}`
      properties.urls.operatorLogout = `/operator/search_flights${suffix}?logout=true`
      properties.urls.login = `/login-signup${suffix}`,
      properties.urls.searchResultsUrl = `/search/search${suffix}`
      properties.urls.reviews = `/about/testimonials${suffix}`
    }
  }

  properties.twitter_share_url = 'https://twitter.com/intent/tweet?text=';
  properties.facebook_share_url = 'https://www.facebook.com/sharer/sharer.php?u=';
  properties.linkedin_share_url = 'https://www.linkedin.com/sharing/share-offsite/?url='  
  properties.prismicEndpoint = 'https://PrivateFly.prismic.io/api/v2'

  if (process.env.LANG_CODE === "fr-fr" | language === "fr-fr") {
    properties.aircraftCategories = ['TURBOPROPULSEUR', 'JETS LÉGERS', 'JET INTERMÉDIAIRE / MEDIUM', 'JETS LONG-COURRIERS', 'JET LOURD', 'AVIONS COMMERCIAUX'];
  } else {
    properties.aircraftCategories = ['Small props', 'Small Jets', 'Medium Jets', 'Long Range Jets', 'Regional Airliners', 'Large Airliners'];
  } 

  return properties
}