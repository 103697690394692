import React from 'react'
import PropTypes from 'prop-types'
import { H5 } from 'styled'

const Heading5 = ({ children, richText }) => {
  return (
    <React.Fragment>
      <H5
        fontSize="18px"
        fontWeight="500"
        richText={richText}
      >
        {children}
      </H5>
    </React.Fragment>
  )
}

export default Heading5

Heading5.propTypes = {
  children: PropTypes.array,
  richText: PropTypes.bool
}
