import React from 'react';
import PropTypes from 'prop-types';

import InternalLink from 'components/links/internalLink';

import { StyledSubMenu, StyledExternalLink, StyledSubMenuLinksWrapper, StyledSubMenuHeading } from '../styled';

const isSwissDomain = process.env.IS_SWISS_DOMAIN;
const isFrenchBuild = process.env.LANG_CODE === 'fr-fr';

const isBlogBuild = process.env.BUILD_BLOG;
const blogOrigin = process.env.BLOG_ORIGIN;

const privateflyDomain = process.env.LOCALE_ORIGIN;

const SubMenuList = ({ sublist, language, location, preview, setDropdown }) => {

  const isLanguageFR = language === 'fr-fr' || language === 'fr-ch';
  const isSwissFR = isSwissDomain == "true" && isLanguageFR;

  let headingUrl = new URL(sublist.primary.menu_title_url.url);
  
  const submenuHeading = preview ? sublist.primary.menu_title[0].text : sublist.primary.menu_title.text;

  let activePath = location.pathname;

  const subMenuHeading = isBlogBuild ? ( 
    <a href={`${privateflyDomain}${headingUrl.pathname}`}> {submenuHeading} </a> 
  ) : (
    <InternalLink
      linkText={submenuHeading}
      path={headingUrl.href.includes("blog.privatefly") ? headingUrl.href : headingUrl.pathname}
      key={headingUrl}
      language={language}
    />
  );

  const closeMenu = (e) => {
    if(window !== undefined) {
      if (e.target.href === window.location.href) setDropdown(false);
    }
  }

  const subMenuLinks = sublist.items.map(el => {
    let url
    let dropDownMenu;

    //convert absolute url too relative path
    url = new URL(el.url_link.url);

    if (isBlogBuild) {
      // if blog, convert submenu urls to corresponding privatefly domain
      let newUrl = new URL(el.url_link.url)

      if(el.url_link.url.includes('blog.privatefly')) url = `${blogOrigin || el.url_link.url}`
      else url = `${privateflyDomain}${newUrl.pathname}`
      
      dropDownMenu = (
        <StyledExternalLink href={url} key={url.href}>
          {el.list_item}
        </StyledExternalLink>
      )
    } else {
      // if not blog build process logic for internal and external links
      const isBlogSubmenuUrl = url.href.includes("blog.privatefly");
      const blogHref = isBlogSubmenuUrl && isSwissFR ? 'https://blog.privatefly.fr/' : blogOrigin || url.href;

      if (url.pathname === "/") {
        dropDownMenu = (
          <StyledExternalLink 
            key={url.href}
            href={blogHref} 
          >
            { el.list_item }
          </StyledExternalLink> 
        )

      } else {
        // fix for 105905 - CH/Swiss menu highlight
        if (isSwissDomain == "true") {
          if(isLanguageFR) activePath = activePath.replace('/fr', '');

          url.pathname = url.pathname.replace(".html", "");
        }

        dropDownMenu = (
          <InternalLink
            linkText={el.list_item}
            path={isBlogSubmenuUrl ? url.href : url.pathname}
            key={el.url_link.url}
            language={language}
            style={{color: '#3C3C3B'}}
            className={!isBlogSubmenuUrl && url.pathname === activePath ? 'active-path' : ''}
          />
        )

      }
    }

    return (
      <StyledSubMenuLinksWrapper 
        onClick={(e)=> closeMenu(e)}
        isFrench={isFrenchBuild || isLanguageFR ? true : false}
      > 
        {dropDownMenu} 
      </StyledSubMenuLinksWrapper>
    )
  })

  return (
    <StyledSubMenu>
      <StyledSubMenuHeading 
        onClick={(e)=> closeMenu(e)}
        isFrench={isFrenchBuild || isLanguageFR ? true : false}
      > 
        {subMenuHeading} 
      </StyledSubMenuHeading> 
      <div style={{marginTop: '19px'}}> {subMenuLinks} </div> 
    </StyledSubMenu> 
  )
}

export default SubMenuList

SubMenuList.propTypes = {
  language: PropTypes.string,
  location: PropTypes.object,
  preview: PropTypes.bool,
  setDropdown: PropTypes.func,
  sublist: PropTypes.object
}
