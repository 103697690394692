import React from 'react';
import PropTypes from 'prop-types';

import createProperties from 'utils/functions/createProperties';

import { StyledLoginWrapper } from '../../styled';

const hostname = process.env.LOCALE_ORIGIN;
const isBlog = process.env.BUILD_BLOG;
const isSwissDomain = process.env.IS_SWISS_DOMAIN;

const Login = ({ username, logoutFunc, language, userIcon, loginText, myProfileText, logoutText }) => {

  const { urls } = createProperties(language);

  if(username.state) {
    let userProfileHref;
    let logoutHref;

    if (username.type === 'Customer') {
      userProfileHref = `${hostname}${urls.customerDashboard}`;
      logoutHref = `${hostname}${urls.logout}`;
    
    } else if (username.type === 'Operator') {
      userProfileHref = `${hostname}${urls.operatorDashboard}`;
      logoutHref = `${hostname}${urls.operatorLogout}`;
    
    } else {
      userIconHref = "";
      logoutHref = "";
      
    }

    if(isSwissDomain == "true" || language === "fr-fr" ) {
      userProfileHref = userProfileHref.replace(".html", "");
      logoutHref = logoutHref.replace(".html", "");
    }

    return (
      <React.Fragment>
        <StyledLoginWrapper>
          <div className="logged-in">
            <a href={userProfileHref}> 
              {myProfileText ? myProfileText : "MY PROFILE"} 
            </a>
          </div>
        </StyledLoginWrapper>

        <StyledLoginWrapper>
          <div className="logged-in">
            <a href={logoutHref} onClick={()=>{ logoutFunc() }}>
              {logoutText ? logoutText : "LOG OUT"}
            </a>
          </div>
        </StyledLoginWrapper>

      </React.Fragment>
    )

  } else {
    const addSuffix = !isSwissDomain || language !== "fr-fr" && isBlog ? '.html' : '';
    const loginHref = `${hostname}${urls.login}${addSuffix}`;
    const userIconText = loginText;

    return (
      <StyledLoginWrapper>
        <a className="login" href={loginHref}>
          {userIconText}
          <img className="user-icon" src={userIcon} />
        </a>
      </StyledLoginWrapper>
    )

  }

};

Login.propTypes = {
  language: PropTypes.string,
  loginText: PropTypes.string,
  logoutFunc: PropTypes.func,
  logoutText: PropTypes.string,
  myProfileText: PropTypes.string,
  userIcon: PropTypes.string,
  username: PropTypes.string,
}

export default Login;