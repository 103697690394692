import React from 'react'
import PropTypes from 'prop-types'
import { H6 } from 'styled'

const Heading6 = ({ children, richText }) => {
  return (
    <React.Fragment>
      <H6
        fontSize="18px"
        fontWeight="500"
        richText={richText}
      >
        {children}
      </H6>
    </React.Fragment>
  )
}

export default Heading6

Heading6.propTypes = {
  children: PropTypes.array,
  richText: PropTypes.bool
}
