import styled from 'styled-components';

export const StyledFooter = styled.footer`
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  background: #D4D6DE;
  margin: 15px 0px 0px 0px;

  * { font-family: Open Sans, sans-serif; }

  @media (min-width: 1024px) {
    margin: 30px 0 82px 0;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    p, a, span, img, form { 
      padding-top: 0em; 
    }
  }
`;

export const StyledBackToTopButton = styled.span`
  color: black;
  font-size: 14px;
  text-align: center;
  font-weight: 600;
  text-decoration: underline;
  margin-bottom: 50px;
  cursor: pointer;

  @media (min-width: 1000px) {
    display: none;
  }
`;

export const StyledFooterTiles = styled.div`
  width: 100%;

  @media (min-width: 1000px) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: wrap;
        flex-flow: wrap;
    margin-bottom: 57px;
    padding: 0;
  }
`;

export const StyledTileWrapper = styled.section`
  width: 100%;
  padding: ${props => (props.padding ? props.padding : "15px 0px")};
  border-bottom: 1px solid rgba(60, 60, 59, 0.2);
  text-align: center;

  :last-child{ margin-bottom: 24px; }

  @media (min-width: 1000px) {
    width: ${props => (props.width ? props.width : "auto")};
    padding: 0 5px;
    border-right: 1px solid rgba(60, 60, 59, 0.2);
    overflow: hidden;
    border-bottom: none;
    text-align: left;

    :first-child{ padding: 0 5px 0 0; }

    :last-child{
      padding: 0 0 0 5px;
      margin-bottom: 0;
      border-right: none;
    }
  }

  @media (min-width: 1200px) {
    padding-top: 0.4em;
    padding: 0 10px;
    :first-child{ padding: 0 10px 0 0; }
    :last-child{ padding: 0 0 0 10px; }
  }

  @media (min-width: 1400px) {
    padding: 0 18px;
    :first-child{ padding: 0 20px 0 0; }
    :last-child{ padding: 0 0 0 20px; }
  }
`;

export const StyledFooterArticleWrapper = styled.div`
  width: 100%;
  padding: 0;
  margin: 0;

  img { 
    width: 25%; 
    margin: 0px 0px 16px 0px; 
  }

  p, a { font-size: 12px; }  
  
  p { margin-bottom: 14px; }
  
  a { 
    color: #4990E2;
    &:hover {
      text-decoration: underline;
    }
  }

  @media (min-width: 1000px) {
    width: ${props => (props.width ? props.width : "100%")};
    padding: ${props => (props.padding ? props.padding : "0")};
    margin: ${props => (props.margin ? props.margin : "0")};

    .footer-article-img {
      width: 42%;
      padding: 7px 7px 7px 0px;
      margin: 0 15px 0 0;
    }
    
    img, p, a { width: 100%; }
    p {margin-bottom: 4px}
    img { margin: 0px; }
  }

  @media (min-width: 1400px) {
    p, a { font-size: 12px; }
    p { margin-bottom: 9px; }
  }
`;

export const StyledArticleContainer = styled.div`
  display: block;
  
  @media (min-width: 600px) { padding: 0 19%; }
  
  @media (min-width: 1000px) { padding: 0; }
`;

export const StyledFooterDiv = styled.div`
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  font-size: 11px;
  letter-spacing: 0.93px;
  font-weight: 600;
  text-transform: uppercase;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
`;

export const StyledCopyrightText = styled.div`
  width: 100%;
  margin-bottom: 12px;

  @media (min-width: 600px) { 
    margin-bottom: 17px;
  }
`;

export const StyledFooterTermLinks = styled.div`
  margin-bottom: 70px;

  a {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin: 0 10px;
    color: black;
    padding-top: 1em;

    &:hover {
      text-decoration: underline;
    }

  }

  @media (min-width: 1000px) {
    margin-bottom: 0;
    a { 
      padding-top: 0px; 
    }
  }
`;

export const StyledHeading = styled.section`
  width: 100%;
  font-size: 11px;
  letter-spacing: 0.93px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 10px;

  @media (min-width: 1000px) {
    margin-bottom: 20px;
  }

  @media (min-width: 1200px) {
    margin-bottom: ${props => props.marginBottom ? props.marginBottom : "25px"};
  }
`;

export const StyledPhoneLink = styled.a`
  color: black;
  font-size: 24px;
  font-family: Raleway, 'sans-serif';
  font-weight: 500;
  
  :hover { 
    color: black;
    text-decoration: underline; 
  }
  
  @media (min-width: 1000px) { 
    font-size: 1.7vw; 
  }
  
  @media (min-width: 1200px) { 
    font-size: 24px; 
  }
`;

export const SocialMediaWrapper = styled.div`
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
`;

export const StyledFlagLink = styled.a`
  color: #e30613;
  font-size: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  img { 
    max-height: 18px; 
    margin-right: 10px;
  }

  p {
    color: #000; 
    font-size: 11px; 
    font-weight: 600; 
    letter-spacing: 0.93; 
    line-height: 11px; 
    margin: 0; 
    text-transform: uppercase 
  }

  &:hover {
    color: black;
    text-decoration: underline;
  }
`;

export const StyledLanguageList = styled.ul`
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 21px 20px 18px 20px;
  margin: 0;
`;

export const LanguageListItem = styled.ul`
  && {
    width: 100%;
    height: 18px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    list-style: none;
    font-family: Open Sans, sans-serif;
    padding-left: 0;
    margin-left: 0;
    margin-top: ${props => (props.search ? '0.5em' : '0em')};
  }

  width: 100%;
  list-style-type: none;
  margin-bottom: 20px;
  width: 25%;
`;

export const StyledOverlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 100px;
  bottom: 0;
  left: 0;
  cursor: auto;
  z-index: 99;
`;

export const DesktopLangSelectPopup = styled.div`
  display: ${props => props.isActive ? "flex": "none"};
  width: 222px;
  position: absolute;
  margin: -50px 0 0 -40px;
  z-index: 99999;
  background: white;
  -webkit-box-shadow: 0 0 12px 0 rgb(72 79 93 / 17%);
          box-shadow: 0 0 12px 0 rgb(72 79 93 / 17%);
  overflow: hidden;

  .lang-select-close-icon {
    maxHeight: 18px;
    position: absolute;
    right: 28px;
    top: 16px;
    cursor: pointer;
  }
`;

export const StyledLangSelectDesktop = styled.p`
  display: none;
  
  @media (min-width: 1000px) {
    display: inline-block;
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 4px 0 0 5px;
    cursor: pointer;

    @media (min-width: 1200px) {
      margin: 4px 0 0 10px;
      font-size: 11px;
    }

    .lang-select-arrow_down {
      display: inline-block;
      border: solid black;
      border-width: 0 2px 2px 0;
      padding: 3px;
      margin: 0 0 2px 5px;
      -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
              transform: rotate(45deg);
      webkit-transform: rotate(45deg);

      @media (min-width: 1200px) {
        margin: 0 0 2px 23px;
      }

    }
  }
`;

export const StyledLangSelectMobile = styled.select`
  width: 135px;
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
  background: #D4D6DE;
  border: none;
  outline: none;
  margin: 0 0 0 10px;

  @media (min-width: 1000px) {
    display: none;
  }
`;