import React, { useState, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'

const windowGlobal = typeof window !== 'undefined' && window;

function MediaQuery({ mobileComponent, desktopComponent, viewPort }) {
  const [ windowViewPort, setViewPort ] = useState(null);

  useLayoutEffect(() => {
    window.addEventListener('resize', handleScreenResize);
    handleScreenResize()
  }, [])

  const handleScreenResize = () => {
    setViewPort(windowGlobal.innerWidth)
  }

  if (windowViewPort > viewPort) {
    return (
      <React.Fragment>
        {desktopComponent}
      </React.Fragment>
    )
  } else {
    return (
      <React.Fragment>
        {mobileComponent}
      </React.Fragment>
    )

  }
}

MediaQuery.propTypes = {
  desktopComponent: PropTypes.object,
  mobileComponent: PropTypes.object,
  viewPort: PropTypes.number,
}

export default MediaQuery

