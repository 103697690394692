export function replaceTelephoneNumbers (richContent, telephone) {

  let textDifference;
  let startPosition;
  let endPosition;
  let matchedString

  let regexUK = /(\+)?(00?\s?)?(44?\s?)?((\(0\))|0)\s?[0-9]{2}\s?[0-9]{4}\s?[0-9]{4}/gi;
  let regexUS = /(\([0-9]{3}\) ?|[0-9]{3}-)[0-9]{3}-[0-9]{4}/gim;
  let regexDE = /(\+49\s?|\(\+49\)\s?){1}([1-9]{2,4})([ \-\/]?[0-9]{1,10})+/gi
  let regexFR = /((\+33\s?(\(0\))?)|(0))\s?1\s?70\s?70\s?77\s?87/gim;
  let regexVariable = /{{telephone}}/gim;

  if (richContent && telephone) {
    richContent
      .map(el => {
        if (el.text !== null) {
          if (regexVariable.test(el.text) || regexUK.test(el.text) || regexFR.test(el.text) || regexDE.test(el.text)) {
            matchedString = el.text.match(regexVariable) || el.text.match(regexUK) || el.text.match(regexFR) || el.text.match(regexDE)
            textDifference = Math.abs(matchedString[0].length - telephone.length);
            startPosition = el.text.indexOf(matchedString[0]);
            endPosition = startPosition + telephone.length
            //check is spans array exists and its length is higher than 0
            if (el.spans && el.spans.length > 0) { 
              let spansWithRemovedTelephoneHyperlink = el.spans
                .map(el => {
                  let positionDifference = Math.abs(el.start - startPosition)

                  //remove any hyperlink that is a phone number from the text. This removes a link only if its position matches position of found string
                    if (el.type === "hyperlink" && el.data.url.startsWith("tel")) {
                      if (positionDifference === 0 || positionDifference === 1) {
                        return undefined 
                      }
                    } else {
                      return el
                    }
                })
                .filter(el => el !== undefined)
              
              el.spans = spansWithRemovedTelephoneHyperlink
            } 

            if (el.spans) {
              el.spans.push({
                start: startPosition,
                end: startPosition + telephone.length,
                type: "hyperlink",
                data: {
                  link_type: "Web",
                  url: `tel:${telephone}`
                }
              })
            }

            if (el.spans) {

              el.spans.map(span => {
                let startPositionDifference = Math.abs(span.start - startPosition)
                let endPositionDifference = Math.abs(span.end - endPosition)

                if (startPositionDifference === 0 || startPositionDifference === 1) {
                  //check if spans has the length of the whole paragraph
                  if (span.end == el.text.length) {
                  span.end = span.end + endPositionDifference
                  } else {
                    span.start = startPosition
                    span.end = startPosition + telephone.length 
                  }
                }

                if (span.start < startPosition && span.end > startPosition) {
                span.end = span.end + textDifference;
                }

                //any elements like hyperlink, em or strong tag after the replaced element should have its start position and length modified
                if (span.start > startPosition) {
                  if (matchedString[0].length > telephone.length) {
                    span.start = span.start - textDifference;
                    span.end = span.end - textDifference;
                  } else {
                    span.start = span.start + textDifference;
                    span.end = span.end + textDifference;
                  }
                }
                //check if spans has the length of the whole paragraph
                if (span.start === 0 && span.end == el.text.length) {
                  span.end = span.end + textDifference
                }
              })
            }

            //push hyperlink with the phone number to the array
            
            //replace phone number
            el.text = el.text.replace(regexVariable, telephone);
            el.text = el.text.replace(regexUK, telephone);
            el.text = el.text.replace(regexFR, telephone);
            el.text = el.text.replace(regexDE, telephone);

            return el
          }
        }
      })

    return richContent
  }
}