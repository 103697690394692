export const hideLiveChat = () => {
  const liveChatContainer = document.querySelector("#chat-widget-container");
  const liveChatHelperContainer = document.querySelector("#livechat-eye-catcher")
  const fsExcludeFlagElement = document.querySelector("#fs-exclude")

  if (fsExcludeFlagElement) {
    if (liveChatContainer) {
      liveChatContainer.style.display = "none"
    } 
    if (liveChatHelperContainer) {
      liveChatHelperContainer.style.display = "none"
    } 
  } else {
    if (liveChatContainer) {
      liveChatContainer.style.display = "block"
    } 
    if (liveChatHelperContainer) {
      liveChatHelperContainer.style.display = "block"
    }
  }
}