import styled from 'styled-components';

export const GridColumn = styled.div`
  width: 91%;
  @media (min-width: 700px) {
    width: 91%;
  }
  
  @media (min-width: 1200px) {
    width: 83.3%;
  }
  @media (min-width: 1600px) {
    width: 66.7%;
    max-width: 1340px;
  }
`;

export const GridInnerColumn = styled.div`
  width: 100%;
  
  @media (min-width: 1200px) {
    width: 83.3%;
    width: ${props => (props.width)};
  }
`;

export const GridRow = styled.div`
  display: flex;
  width: ${props => (props.width ? props.width : "100%")};
  justify-content: ${(props) => (props.mobileCentered === false ? 'start' : 'center')};
  border-bottom: ${(props) => (props.isAircraftIndex ? '2px solid #ddd' : 'none')};
  padding-bottom: ${(props) => {
    if (props.isAircraftIndex) {
      return '25px'
    } else if (props.paddingBottom) {
      return props.paddingBottom
    } else {
      return "initial"
    }
  }};
  padding-top: ${(props) => {
    if (props.paddingTop) {
      return props.paddingTop
    } else {
      return "initial"
    }
  }};
  margin-top: ${(props) => {
    if (props.marginTop) {
      return props.marginTop
    } else {
      return "initial"
    }
  }};
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : 'unset')};

  &:last-of-type {
    border-bottom: ${(props) => (props.isAircraftIndex && 'none')};
    padding-bottom: ${(props) => (props.isAircraftIndex && '45px')};
  }

  @media (min-width: 1200px) {
    border-bottom: ${(props) => (props.isAircraftIndex && 'none')};
    justify-content: ${(props) => (props.desktopCentered === false ? 'start' : 'center')};
  }
`;

export const StyledSidebarColumn = styled(GridColumn)`
  && {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    order: ${props => (props.order)};
    width: 100%;
    
    margin-top: ${(props) => (props.hasWidget && '15px')};
    border-top: ${(props) => (props.hasWidget && '1px solid rgba(99,100,99, 0.2)')};
    
    @media (min-width: 1200px) {
      justify-content: normal;
      align-items: flex-end;
      width: ${(props) => (props.isAircraftTemplate || props.hasWidget ? '32%' : '30%')};
      position: ${(props) => (props.hasWidget || props.isAircraftTemplate ? 'relative' : 'unset')};
      top: ${(props) => (props.isAircraftTemplate ? '-100px' : props.hasWidget ? '-200px' : 'unset')};
    }

    @media (min-width: 1200px) and (max-width:1440px) { 
       margin-bottom: 300px;
    }
  }
`;

export const StyledAircraftSidebarColumn = styled(GridColumn)`
  && {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    order: ${props => (props.mobileOrder)};
    width: 100%;

    @media (min-width: 1200px) {
      justify-content: normal;
      align-items: flex-end;
      order: ${props => (props.desktopOrder)};
      width: 30%;
    }
  }
`;

export const StyledHeaderRow = styled.header`
  height: 62px;
  background: #D4D6DE;
  position: sticky;
  top: 0;
  z-index: 10003;

  @media (min-width: 1024px) {
    height: 80px;
  }
`;

export const AircraftTemplateColumn = styled.div`
  order: ${props => (props.mobileOrder)};
  width: ${props => (props.width ? props.mobileWidth : "100%")};

  @media (min-width: 1200px) {
    justify-content: normal;
    align-items: flex-end;
    width: ${props => (props.width ? props.width : "100%")};
    order: ${props => (props.desktopOrder)};
  }
`;

export const AircraftTemplateRow = styled.div`
  display: flex;
  width: 100%;
  max-width: 1336px;
  justify-content: ${(props) => (props.mobileCentered === false ? 'start' : 'center')};
  padding: 0 25px;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "initial")};
  margin-left: auto;
  margin-right: auto;

  @media(min-width: 768px) {
    padding: 0 40px;
    
  }

  @media (min-width: 1200px) {
    justify-content: ${(props) => (props.desktopCentered === false ? 'start' : 'center')};
  }

  article {
    @media (min-width: 1200px) {
      max-width: 750px
    }
  }
`;

export const FullWidthContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1336px;
  height: ${props => props.isBannerImage ? "100%" : "unset"};
  justify-content: ${props => props.justifyContent ? props.justifyContent : "center"};
  align-items: flex-start;
  align-items: stretch;
  margin-left: auto;
  margin-right: auto;
  padding: 0 25px;

  margin-bottom: ${props => {
    if (props.isHomePage) return "40px"
    else if (props.marginBottom) return props.marginBottom
    else return "initial"
  } };
  margin-top: ${props => props.isHomePage && "40px" };

  overflow: ${props => props.overflowX ? props.overflowX : "visible"};

  @media(min-width: 768px) {
    padding: 0 40px;
  }

  h3 {
    font-size: ${props => props.isHomePage && "24px !importtant"};
    margin: 0 !important;

    @media(min-width: 1024px) {
      font-size: ${props => props.isHomePage && "32px !important"};
    }
  }
`

export const FullWidthCardContainer = styled.div`
  display: flex;
  width: 100%;
  height: ${props => props.isBannerImage ? "100%" : "unset"};
  max-width: 1336px;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: stretch;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: ${props => props.marginBottom ? props.marginBottom : "0"}; 
  padding: 0 25px;

  @media screen and (min-width:320px) and (max-width:820px) and (orientation:landscape) {
    justify-content: space-between;
  }

  @media(min-width: 768px) {
    justify-content: space-between;
    width: 100%;
    padding: 0 40px;
  }
`

export const FullWidthSmallCardsContainer = styled.div`
  display: ${props => props.isCarousel === false ? "none" : "flex"};
  width: 100%;
  max-width: 1336px;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-items: stretch;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: ${props => props.marginBottom ? props.marginBottom : "0"}; 
  padding: 0 25px;

  margin-top: ${props => props.marginTopMobile ? props.marginTopMobile : "0"};
  margin-bottom: ${props => props.marginBottomMobile && props.marginBottomMobile };

  @media (min-width: 640px) {
    display: flex;
  }


  @media screen and (min-width:320px) and (max-width:820px) and (orientation:landscape) {
    justify-content: space-between;
    padding-bottom: 20px;
  }

  @media(min-width: 768px) {
    width: 100%;
    padding: 0 40px;
    padding-bottom: 40px;
    justify-content: space-between;
  }

  @media(min-width: 1024px) {
    flex: 0 0 246px;
      
      margin-top: ${props => props.marginTop && props.marginTop};
      /* margin-bottom: ${props => props.marginBottom ? props.marginBottom : "40px"}; */
      padding-bottom: 40px;
    }
  
    @media(min-width: 1200px) {
      justify-content: space-between;
    }

`

export const FullWidthSmallCardsWithSeparatorContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 1336px;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: stretch;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: ${props => props.marginBottom ? props.marginBottom : "0"}; 
  padding: 0 25px;

  @media screen and (min-width:320px) and (max-width:820px) and (orientation:landscape) {
    justify-content: space-between;
  }

  @media(min-width: 768px) {
    justify-content: center;
    width: 100%;
    padding: 0 40px;
  }
`

export const ColumnLarge = styled.div`
  position: relative;
  max-width: 100%;
  margin-top: 25px;
  width: 100%;

  @media(min-width: 1200px) {
    width: ${props => props.width ? props.width : "100%"};
    max-width: ${props => props.maxWidth ? props.maxWidth : "720px"};
  }
  
  @media(min-width: 1300px) {
    max-width: ${props => props.maxWidth ? props.maxWidth : "790px"};
  }
`

export const FullWidthTwoColumnContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1336px;
  height: ${props => props.isBannerImage ? "100%" : "unset"};
  justify-content: ${props => props.justifyContent ? props.justifyContent : "center"};
  align-items: flex-start;
  align-items: stretch;
  margin-left: auto;
  margin-right: auto;
  padding: 0 25px;

  @media(min-width: 768px) {
    padding: 0 40px;
  }
`