import React from 'react'
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import ExternalLink from './ExternalLink';

const suffix = process.env.GATSBY_URL_SUFFIX;
const host = process.env.LOCALE_ORIGIN;
const isBlogCheck = process.env.BUILD_BLOG;

function InternalLink({ path, linkText, style, linkComponent, className, language, onMouseEnter, onMouseLeave }) {
  let internalPath;
  let isExternalLink

  if (path) {
      // parse url, second argument set to empty object to avoid parser using current browser location as baseURL
    let url = new URL(path, host);

    if (url.pathname === "/") {
      internalPath = url.pathname

      if (process.env.IS_SWISS_DOMAIN == "true" && !path.includes('blog.privatefly')) {
        if (language === "fr-fr") internalPath = `/fr${internalPath}`
      }

    } else if (url.pathname.endsWith('/')) {
      internalPath = url.pathname.slice(0, url.pathname.lastIndexOf("/"))

      if (process.env.IS_SWISS_DOMAIN == "true" && !path.includes('blog.privatefly')) {
        if (language === "fr-fr") internalPath = `/fr${internalPath}`
      }
    } else if (url.pathname.endsWith('.html')) { 
      internalPath = url.pathname.replace(".html", "");

      if (process.env.IS_SWISS_DOMAIN == "true" && !path.includes('blog.privatefly')) {
        if (language === "fr-fr") internalPath = `/fr${internalPath}`
      }

    } else {
      internalPath = url.pathname

      if (process.env.IS_SWISS_DOMAIN == "true" && !path.includes('blog.privatefly')) {
        if (language === "fr-fr") internalPath = `/fr${internalPath}`
      }
    }

    if (url) {
      isExternalLink = url.href.includes('blog.privatefly') || internalPath.includes('/customer/customerDashboard') || internalPath.includes('/contactez-nous/contactez') || internalPath.includes('/location-jet-prive') || internalPath.includes('/private-jet-charter/private-jet-charter') || internalPath.includes('/contactUs/contact') || internalPath === '/login-signup' || (url.href.includes('newsletter-join') && isBlogCheck) || (url.href.includes('souscrire') && isBlogCheck);
    }

  if (path) {
    if (path.startsWith("tel")) {
      return (
        <a href={path} style={style}
          className={className}
        >
          {linkText}
        </a>
      )
    }
  }
    
  if (isExternalLink) {
    if (url) {
      return (
        <ExternalLink 
          url = {url.href.includes("blog.privatefly") || url.href.includes("newsletter-join") || url.href.includes('souscrire') ? url.href : `${internalPath}${suffix}`} 
          style={style}
          className={className}
          linkComponent={linkComponent}
          linkText={linkText}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        />
      )
    }
    
  } else {
    return (
      <Link 
        to={internalPath === "/" ? `${internalPath}` : `${internalPath}${suffix}`} 
        style={style}
        className={className}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {linkText ? linkText : linkComponent}
      </Link>
    )
  }
}
}

InternalLink.propTypes = {
  className: PropTypes.string,
  language: PropTypes.string,
  linkComponent: PropTypes.object,
  linkText: PropTypes.string,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  path: PropTypes.string,
  style: PropTypes.object,
}

export default InternalLink;

