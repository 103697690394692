import React from 'react'

export function loadTrustpilotScript(isReviewPage, shouldLoadTrustPilotScript) {
  if (isReviewPage || shouldLoadTrustPilotScript) {
    return (
      <script 
        async
        type="text/javascript" 
        src="https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
      />
    )
  } else {
    return null
  }
}