import React from 'react';

import { FullWidthContainer, StyledHeaderRow } from 'components/grid/styled';

const HeaderWrapper = ComponentToWrap => props => {
  return (
    <StyledHeaderRow>
      <FullWidthContainer style={{height: '100%'}}>
        <ComponentToWrap {...props} />
      </FullWidthContainer>
    </StyledHeaderRow>
  );
};

export default HeaderWrapper;